import { createCookieStorage } from '@olxeu-eprivacy-storage/js/cookie';
import { CookieScope, ENV, getReservoirLink } from '@olxeu-eprivacy-storage/js/storage';
import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import { ajaxCall } from '../utils';
import { jsonDecode } from '../utils/json/decode';
import { isEprivacyStorageRequired } from './isEprivacyStorageRequired';
import { REQUIRED_VERSION } from './required-version';

/**
 * @typedef {import('@olxeu-eprivacy-storage/js/storage').CookieStorage} CookieStorage
 */

/* eslint-disable no-unused-vars */
/**
 * Get empty facade.
 * @returns {CookieStorage}
 */
export function getEmptyStorage() {
  return {
    set: function set(cookieName, value, options) {
      console.log('empty storage');
      return undefined;
    },
    get: function get(cookieName) {
      console.log('empty storage');
      return undefined;
    },
    remove: function remove(cookieName, options) {
      console.log('empty storage');
    },
  };
}
/* eslint-enable no-unused-vars */

/**
 * Local cookie storage instance.
 * @type {CookieStorage}
 */
export var cookieStorage = getEmptyStorage();
export var isReady = false;

/**
 * Init local cookie storage - either use the version from `window` or load the definitions
 * @param {(storage: CookieStorage) => void} callbackFn
 */
export function initCookieStorage(callbackFn) {
  if (isReady) {
    callbackFn(cookieStorage);
    return;
  }

  if (isEprivacyStorageRequired()) {
    if (typeof ninjaConfig.getEprivacySDK === 'function') {
      cookieStorage = ninjaConfig.getEprivacySDK();

      isReady = true;
      callbackFn(cookieStorage);
    } else if (window.eprivacyStorage && window.eprivacyStorage[REQUIRED_VERSION]) {
      cookieStorage = window.eprivacyStorage[REQUIRED_VERSION];

      isReady = true;
      callbackFn(cookieStorage);
    } else {
      loadCookieStorage(function (storage) {
        cookieStorage = storage;

        if (cookieStorage) {
          if (!window.eprivacyStorage) {
            window.eprivacyStorage = {};
          }

          window.eprivacyStorage[REQUIRED_VERSION] = storage;

          isReady = true;
          callbackFn(cookieStorage);
        }
      });
    }
  } else {
    // If cookie consent is not required for this region, return cookie storage without consent restrictions
    cookieStorage = createCookieStorage(false);
    isReady = true;
    callbackFn(cookieStorage);
  }
}

/**
 * Fetches definition and fully inits the storage
 * @param {(storage: CookieStorage) => void} callbackFn
 */
export function loadCookieStorage(callbackFn) {
  var url;

  if (hydraConfig.eprivacy_scope) {
    url = getReservoirLink(CookieScope[hydraConfig.eprivacy_scope], hydraConfig.debug ? ENV.STG : ENV.PRD);

    ajaxCall(
      'get',
      url,
      function (data) {
        var categories = jsonDecode(data);
        var storage = createCookieStorage(true, categories);
        callbackFn(storage);
      },
      null,
      function (e) {
        console.error('Ninja can not load cookie category definitions!', url, e);
        callbackFn(getEmptyStorage());
      }
    );
  } else {
    console.error('Ninja can not load cookie category definitions - missing `eprivacy_scope` for current region!');
    callbackFn(getEmptyStorage());
  }
}
