import { inArray } from '../utils';

// import { cookieStorage } from './cookieStorage';

/**
 * Clean a given cookie from all possible domains and paths
 * @param {string} cName - the cookie name
 * @param {string[]} [allowedDomains=[]] - list of domains to skip cleanup
 * @param {string[]} [allowedPaths=[]] - list of paths to skip cleanup
 */
export function cleanCookie(cName, allowedDomains, allowedPaths) {
  var d = window.location.hostname.split('.');
  var cookiesToRemove = [encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'];
  var cPath;
  var cookieBase;
  var domain;
  var domainsWithPrefix;
  var p;
  var j;

  // Valid domains require at least one dot
  while (d.length > 1) {
    domain = d.join('.');
    domainsWithPrefix = [];

    if (!inArray(allowedDomains || [], domain)) {
      domainsWithPrefix = [domain];
    }

    if (!domain.match(/^www\./)) {
      domainsWithPrefix.push('www.' + domain);
      domainsWithPrefix.push('.www.' + domain);
    }

    for (j = 0; j < domainsWithPrefix.length; j++) {
      cookieBase = encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + domainsWithPrefix[j] + ' ;path=';

      // clean cookie at root path
      if (!inArray(cookiesToRemove, cookieBase + '/')) {
        cookiesToRemove.push(cookieBase + '/');
      }

      p = location.pathname.split('/');
      // clean cookie at all possible sub-paths
      while (p.length > 0) {
        cPath = p.join('/');
        if (cPath !== '/' && cPath !== '' && !inArray(allowedPaths || [], cPath)) {
          cookiesToRemove.push(cookieBase + cPath);
        }

        p.pop();
      }
    }

    d.shift();
  }

  for (var i = 0; i < cookiesToRemove.length; i++) {
    // console.log('Removing ', cName, cookiesToRemove[i]);
    document.cookie = cookiesToRemove[i];
    // TODO: use ePrivacy SDK to modify cookies
    // cookieStorage.remove();
  }

  return cookiesToRemove;
}
