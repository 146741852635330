// base64 character set, plus padding character (=)
var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
// Regular expression to check formal correctness of base64 encoded strings
var b64re = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;

/** Base64 polyfills, based on https://github.com/MaxArt2501/base64-js/blob/master/base64.js */
export function atob(value) {
  if (typeof window.atob === 'function') {
    return window.atob(value);
  }

  // atob can work with strings with whitespaces, even inside the encoded part,
  // but only \t, \n, \f, \r and ' ', which can be stripped.
  var string = String(value).replace(/[\t\n\f\r ]+/g, '');
  if (!b64re.test(string)) {
    throw new TypeError('Failed to execute atob: The string to be decoded is not correctly encoded.');
  }

  // Adding the padding if missing, for semplicity
  string += '=='.slice(2 - (string.length & 3));
  var bitmap;
  var result = '';
  var r1;
  var r2;
  var i = 0;

  // eslint-disable-next-line
  for (; i < string.length; ) {
    bitmap =
      (b64.indexOf(string.charAt(i++)) << 18) |
      (b64.indexOf(string.charAt(i++)) << 12) |
      ((r1 = b64.indexOf(string.charAt(i++))) << 6) |
      (r2 = b64.indexOf(string.charAt(i++)));

    result +=
      r1 === 64
        ? String.fromCharCode((bitmap >> 16) & 255)
        : r2 === 64
        ? String.fromCharCode((bitmap >> 16) & 255, (bitmap >> 8) & 255)
        : String.fromCharCode((bitmap >> 16) & 255, (bitmap >> 8) & 255, bitmap & 255);
  }
  return result;
}

export function btoa(value) {
  if (typeof window.btoa === 'function') {
    return window.btoa(value);
  }

  var string = String(value);
  var bitmap;
  var a;
  var b;
  var c;
  var result = '';
  var i = 0;
  var rest = string.length % 3; // To determine the final padding

  // eslint-disable-next-line
  for (; i < string.length; ) {
    if ((a = string.charCodeAt(i++)) > 255 || (b = string.charCodeAt(i++)) > 255 || (c = string.charCodeAt(i++)) > 255) {
      throw new TypeError('Failed to execute btoa: The string to be encoded contains characters outside of the Latin1 range.');
    }

    bitmap = (a << 16) | (b << 8) | c;
    result += b64.charAt((bitmap >> 18) & 63) + b64.charAt((bitmap >> 12) & 63) + b64.charAt((bitmap >> 6) & 63) + b64.charAt(bitmap & 63);
  }

  // If there's need of padding, replace the last 'A's with equal signs
  return rest ? result.slice(0, rest - 3) + '==='.substring(rest) : result;
}

// Encode value
export function eucex(value) {
  var encode;

  try {
    encode = encodeURIComponent(decodeURIComponent(value));
  } catch (ignore) {
    encode = '';
  }

  return encode;
}

// Decode value
export function deucex(value) {
  var decode;

  try {
    decode = decodeURIComponent(value);
  } catch (ignore) {
    decode = '';
  }

  return decode;
}

/**
 * Inserts a script in the document and calls callback
 * @param {string} [url]
 * @param {string} [id]
 * @param {Function} [callback]
 * @param {string} [content] - if provided, will be added as innerHTML to the script element
 */
export function loadScript(url, id, callback, content) {
  /**
   * @type {HTMLScriptElement}
   */
  var script;
  var qa = 'createElement';
  var docHead;
  var docBody;
  var docScript;
  var oldTag;

  if (id) {
    oldTag = document.getElementById(id);
    if (oldTag) {
      // Already loaded
      if (callback) {
        callback(url);
      }
      return;
    }
  }

  if (typeof document[qa] === 'function') {
    // Only support browser that have document.createElement function
    script = document[qa]('script');
    script.type = 'text/javascript';
    script.async = true;
    if (id) {
      script.id = id;
    }

    if (callback) {
      if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback(url);
          }
        };
      } else {
        //Others
        script.onload = function () {
          callback(url);
        };
      }
    }

    if (url) {
      script.src = url;
    }

    if (content) {
      script.innerHTML = content;
    }

    docHead = document.getElementsByTagName('head');
    docBody = document.getElementsByTagName('body');
    docScript = document.getElementsByTagName('script');

    if (docHead && docHead[0]) {
      docHead[0].appendChild(script);
    } else if (docBody && docBody[0]) {
      docBody[0].appendChild(script);
    } else if (docScript && docScript[0] && docScript[0].parentNode) {
      docScript[0].parentNode.insertBefore(script, docScript[0]);
    }
  }
}

export function getCurrentPath() {
  var pathname = window.location.pathname;
  if (pathname.charAt(0) === '/' && pathname.length > 1) {
    pathname = pathname.slice(1);
  }
  if (pathname.charAt(pathname.length - 1) === '/' && pathname.length > 1) {
    pathname = pathname.slice(0, pathname.length - 1);
  }
  if (pathname.search(/\./) > 0) {
    pathname = pathname.replace(/(\.[a-z]+$)/, '');
  }
  if (pathname === '/' || pathname === '') {
    pathname = 'home';
  }
  return pathname;
}

export function isArray(array) {
  return Object.prototype.toString.call(array) === '[object Array]';
}

/**
 * Finds element in array
 * @param {Array} haystack
 * @param {*} needle
 * @returns {boolean}
 */
export function inArray(haystack, needle) {
  if (Array.prototype.includes) {
    return haystack.includes(needle);
  }

  var indexA;
  var indexB = haystack.length;

  for (indexA = 0; indexA < indexB; indexA = indexA + 1) {
    if (haystack[indexA] === needle) {
      return true;
    }
  }

  return false;
}

export function indexOf(array, el, fromIndex) {
  // based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
  if (array === null || array === undefined) throw TypeError('indexOf called on null or undefined');
  if (Array.prototype.indexOf) {
    return array.indexOf(el, fromIndex);
  }

  var that = Object(array);
  var Len = that.length >>> 0;
  var i = Math.min(fromIndex | 0, Len);

  if (i < 0) i = Math.max(0, Len + i);
  else if (i >= Len) return -1;

  if (el === undefined) {
    // undefined
    for (; i !== Len; ++i) if (that[i] === undefined && i in that) return i;
    // eslint-disable-next-line
  } else if (el !== el) {
    // NaN
    return -1; // Since NaN !== NaN, it will never be found. Fast-path it.
  } // all else
  else {
    for (; i !== Len; ++i) {
      if (that[i] === el) {
        return i;
      }
    }
  }

  return -1; // if the value was not found, then return -1
}

/**
 * Send ajax call
 * @param {'get' | 'post'} method The method of request GET/POST
 * @param {String} url The URL to which to send the request
 * @param {Function} callback callback function on success
 * @param {any} params TODO: do we need this params here? can't find any place where we pass this params
 * @param {Function} onError callback function on error
 * @returns {void}
 */
export function ajaxCall(method, url, callBack, params, onError) {
  var xdr;
  var xmlhttp;

  if (window.XDomainRequest) {
    xdr = new XDomainRequest();

    if (xdr) {
      xdr.onload = function () {
        callBack(xdr.responseText, params);
      };

      xdr.onerror = function () {
        if (onError) {
          onError('xdr.onerror ' + method + ' ' + url);
        }
      };

      xdr.open(method, url, true);
      xdr.send();
    }
  } else {
    if (!window.XMLHttpRequest) {
      // code for IE6, IE5
      try {
        xmlhttp = new ActiveXObject('MSXML2.XMLHTTP');
      } catch (ignore) {
        xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
      }
    } else {
      // code for IE7+, Firefox, Chrome, Opera, Safari
      xmlhttp = new XMLHttpRequest();
    }

    try {
      xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState === 4) {
          callBack(xmlhttp.responseText, params);
        }
      };

      xmlhttp.open(method, url, true);
      xmlhttp.onerror = function () {
        if (onError) {
          onError('xmlhttp.onerror ' + method + ' ' + url);
        }
      };
      xmlhttp.send();
    } catch (err) {
      if (onError) {
        onError((err || '') + ' ' + method + ' ' + url);
      }

      callBack('', params);
    }
  }
}

export function getProtocol(optionSsl, optionNormal) {
  return window.location.protocol === 'https:'
    ? 'https://' + (undefined !== optionSsl ? optionSsl : '')
    : 'http://' + (undefined !== optionNormal ? optionSsl : '');
}

// TODO: Consider using external unicode normalization lib
export function convertToLatin(string) {
  /* eslint-disable */
  var latin_map = {
    '\u00e1': 'a', // á
    '\u00c1': 'A', // Á
    '\u00e9': 'e', // é
    '\u00c9': 'E', // É
    '\u00ed': 'i', // í
    '\u00cd': 'I', // Í
    '\u00f3': 'o', // ó
    '\u00d3': 'O', // Ó
    '\u00fa': 'u', // ú
    '\u00da': 'U', // Ú
    '\u00f1': 'n', // ñ
    '\u00d1': 'N', // Ñ
  };
  /* eslint-enable */
  return string.replace(/[^A-Za-z0-9\-_]/g, function (a) {
    return latin_map[a] || a;
  });
}

/**
 * Converts object to query-style string
 * @param {Record<string, any>} obj Data object
 * @returns {String}
 */
export function objectToQueryString(obj) {
  if (typeof obj === 'object') {
    var key;
    var parts = [];

    for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        parts.push(key + '=' + obj[key]);
      }
    }

    return parts.join('&');
  }
  return '';
}

// Get the current unixtimestamp
export function getNow() {
  var date = new Date();

  return Math.round(date.getTime() / 1000);
}

export function extend(obj1, obj2) {
  var result = {};
  var key;

  if (Object.assign) {
    return Object.assign(Object.assign({}, obj1), obj2);
  }

  for (key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      result[key] = obj1[key];
    }
  }

  for (key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      result[key] = obj2[key];
    }
  }

  return result;
}
