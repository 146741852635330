import { convertToLatin, deucex } from '../utils';

// Internal function - Collect the invite param from the URL
export function getInvite() {
  var i;
  var queries = window.location.search.replace(/^\?/, '').split('&');
  var split;
  var searchObject = {};
  var invite;
  var source;
  var finalInvite;
  var tmpInvite = [];
  var hash;

  for (i = 0; i < queries.length; i = i + 1) {
    split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }

  // Try with invite
  invite = searchObject.invite || null;
  if (invite !== null) {
    tmpInvite = parseInvite(invite);
  } else {
    // Try with utm
    if (undefined !== searchObject.utm_source) {
      tmpInvite = parseUtm(searchObject);
    } else {
      // Try with xtor
      hash = getHash();
      if (hash !== null) {
        tmpInvite = parseXtor(hash);
      } else {
        if (undefined !== searchObject.xtor) {
          tmpInvite = parseXtor('xtor=' + searchObject.xtor);
        } else {
          if (undefined !== searchObject.gclid) {
            tmpInvite = ['adwords', searchObject.gclid];
          } else {
            return null;
          }
        }
      }
    }
  }

  if (tmpInvite[0]) {
    source = tmpInvite[0];
    tmpInvite.shift();

    finalInvite =
      convertToLatin(deucex(source))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-') +
      '_' +
      convertToLatin(deucex(tmpInvite.join('--')))
        .replace(/\s/g, '')
        .replace(/NINJA_SEPARATOR/g, '-');

    return finalInvite;
  } else {
    return null;
  }
}

/**
 * Internal function - Get the invite throw invite
 * @param {string} invite
 * @returns Array
 */
export function parseInvite(invite) {
  var inviteParams = invite.match(/^\s*([a-zA-Z0-9\-]{1,64})(?:_([a-zA-Z0-9%\-]{0,128})).*/);

  if (inviteParams !== null && inviteParams.length > 0) {
    return [inviteParams[1], inviteParams[2]];
  } else {
    return [];
  }
}

/**
 * Internal function - Get the invite throw utm
 * @param {Record<string, any>} searchObject
 * @returns any[]
 */
export function parseUtm(searchObject) {
  var inviteParams = [];
  var params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id'];
  var index;

  for (index = 0; index < params.length; index++) {
    if (undefined !== searchObject[params[index]]) {
      inviteParams.push(searchObject[params[index]].replace(/[\-_\.]/g, 'NINJA_SEPARATOR'));
    }
  }

  return inviteParams;
}

/**
 * Internal function - Get the invite throw xtor
 * @param {String} hash
 * @returns String[]
 */
export function parseXtor(hash) {
  var inviteParams = [];
  var match;
  var matchBracket = hash.match(/\[([0-9a-z\-]+)\]/gi);
  var index;
  var cleanedHash = hash;

  if (matchBracket !== null) {
    for (index = 0; index < 8; index++) {
      if (undefined !== matchBracket[index]) {
        cleanedHash = cleanedHash.replace(matchBracket[index], matchBracket[index].replace('[', '').replace(']', '').replace(/\-/g, ''));
      }
    }
  }
  match = cleanedHash.match(
    /xtor=([a-zA-Z0-9_\.]+)-([a-zA-Z0-9_\.]+)-?([a-zA-Z0-9_\.]+)?-?([a-zA-Z0-9_\.]+)?-?([a-zA-Z0-9_\.]+)?-?([a-zA-Z0-9_\.]+)?-?([a-zA-Z0-9_\.]+)?-?([a-zA-Z0-9_\.]+)?/
  );

  if (match !== null) {
    for (index = 1; index < 8; index++) {
      if (undefined !== match[index]) {
        inviteParams.push(match[index].replace(/[\-_\.]/g, 'NINJA_SEPARATOR'));
      }
    }
  }

  return inviteParams;
}

// Internal function - Collect the current host
export function getHost() {
  return window.location.host || null;
}

// Internal function - Return the host deppend on the environment
export function getHydraHost() {
  return 'tracking.olx-st.com/h/v2/';
}

// Internal function - Collect the current hash
export function getHash() {
  return window.location.hash || null;
}

// Internal function - Collect the referrer
export function getReferrer() {
  return document.referrer || null;
}

/**
 * Internal function - Collect the pageName
 * @param {string} pageName
 * @returns string
 */
export function getPageName(pageName) {
  var page = null;

  if (pageName) {
    page = deucex(pageName.toLowerCase());
  }

  return page;
}

/**
 * Internal function - Collect the eventName
 * @param {Array} eventParams
 * @returns Object
 */
export function getEventData(eventParams) {
  var eventData = {};

  if (eventParams) {
    if (eventParams[0]) {
      eventData.category = deucex(eventParams[0]);
    }
    if (eventParams[1]) {
      eventData.action = deucex(eventParams[1]);
    }
    if (eventParams[2]) {
      eventData.label = deucex(eventParams[2]);
    }
    if (eventParams[3]) {
      eventData.value = deucex(eventParams[3]);
    }
    if (eventParams[4]) {
      eventData.interact = deucex(eventParams[4]);
    }
  }

  return eventData;
}
