import regionConfig from './config/region';

export function makeMapping(tracker, key, value) {
  var flag;

  if (key === 'trackEvent' || key === 'trackPage') {
    flag = 'defaultTrackEvent';
  } else {
    flag = 'defaultUseParameter';
  }

  if (regionConfig.config && regionConfig.config[tracker] && regionConfig.config[tracker][flag] === true) {
    return mappingValue(tracker, key, value);
  } else {
    return mappingMarketingValue(tracker, key, value);
  }
}

/**
 * Calls `makeMapping` on each key-value pair of the provided object
 * @param {String} trackerKey Tracker key
 * @param {Record<string, any>} obj The data object to map
 * @returns {Record<string, any>}
 */
export function makeObjectMapping(trackerKey, obj) {
  var mapped;
  var acc = {};
  var key;

  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      mapped = makeMapping(trackerKey, key, obj[key]);

      if (mapped.key !== false) {
        acc[mapped.key] = mapped.value;
      }
    }
  }

  return acc;
}

export function mappingValue(tracker, key, value) {
  var newKey;
  var newValue;

  if (undefined === regionConfig.map[key]) {
    // If the key do not exists in the map, return all without change
    newKey = key;
    newValue = value;
  } else {
    if (undefined === regionConfig.map[key].N || undefined === regionConfig.map[key].N[tracker]) {
      // The key exists but no change for this tracker
      newKey = key;
    } else {
      // The key exists and has a change for this tracker
      newKey = regionConfig.map[key].N[tracker];
    }

    if (undefined === regionConfig.map[key].V) {
      // The key exists but no change for values
      newValue = value;
    } else {
      if (undefined === regionConfig.map[key].V[value]) {
        // The key exists but no change for this value
        newValue = value;
      } else {
        if (undefined === regionConfig.map[key].V[value][tracker]) {
          // The key exists but no change for values for this tracker
          newValue = value;
        } else {
          // The key exists and has a change for values for this tracker
          newValue = regionConfig.map[key].V[value][tracker];
        }
      }
    }
  }

  return {
    key: newKey,
    value: newValue,
  };
}

export function mappingMarketingValue(tracker, key, value) {
  var newKey;
  var newValue;
  if (undefined === regionConfig.map[key]) {
    // If the key do not exists in the map, do not track
    newKey = false;
  } else {
    if (undefined === regionConfig.map[key].N || undefined === regionConfig.map[key].N[tracker]) {
      // The key exists but not for this tracker
      newKey = false;
    } else {
      // The key exists and has a change for this tracker
      newKey = regionConfig.map[key].N[tracker];
    }

    if (regionConfig.map[key].V === undefined) {
      // The key exists but no change for values
      newValue = key === 'trackPage' || key === 'trackEvent' ? false : value;
    } else {
      if (undefined === regionConfig.map[key].V[value]) {
        // The key exists but no change for this value
        newValue = key === 'trackPage' || key === 'trackEvent' ? false : value;
      } else {
        if (undefined === regionConfig.map[key].V[value][tracker]) {
          // The key exists but no change for values for this tracker
          newValue = key === 'trackPage' || key === 'trackEvent' ? false : value;
        } else {
          // The key exists and has a change for values for this tracker
          newValue = regionConfig.map[key].V[value][tracker];
        }
      }
    }
  }

  return {
    key: newKey,
    value: newValue,
  };
}
