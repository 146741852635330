import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { ChannelName, EVENTS, JSQR_URL, LAQUESIS_QA_URL, LAQUESIS_SURVEY_URL, SPLITTER_CDN } from '../const';
import { cookieStorage, getCookieExpirationDate, getCookieName } from '../cookies';
import { collectCalls, cookieFromLq, currentSession, currentSessionLong, getDefaultParams, trackError } from '../core';
import { getHydraHost } from '../core/utils';
import { makeMapping } from '../mapping';
import {
  ajaxCall,
  atob,
  deucex,
  eucex,
  getCurrentPath,
  getNow,
  getProtocol,
  inArray,
  indexOf,
  loadScript,
  objectToQueryString,
} from '../utils';
import { triggerCustomEvent } from '../utils/event';
import { jsonDecode } from '../utils/json/decode';
import { jsonEncode } from '../utils/json/encode';
import { hasUserInteractedWithPage } from '../web-vitals';
import { convertString, currentPlatform, firstTrackPage } from './hydra';

var currentLqPush;

var retries = 0;
var trackPageRetries = 0;
var initPushed = false;
// var cookieRegistry = null;
var listOfActiveTests = [];
var listOfActiveFlags = [];
var lqDefinition = [];
var userId = null;
var localTestsCookieValue = null;
var localFlagsCookieValue = null;
var localSurveysCookieValue = null;
var counter = 0;
var activeSurvey = false;
var isLaquesisQa = false;
var cookieName = getCookieName('laquesis');
var cookieNameFf = getCookieName('laquesisff');
var cookieNameSu = getCookieName('laquesissu');
var cookieNameLq = getCookieName('lqstatus');
var cookieNameQa = getCookieName('laquesisqa');

export var listOfActiveSurveys = {};

// Link event
var isLinkEvent = false;

// QA
var qa = {
  enable: false,
  icon: true,
  style: true,
  expires: 2 * 60,
};

// Internal function - Init the tracker
export function init() {
  var qaMode = false;
  var now = null;
  var lqDefinitionString;
  var mustRefresh = false;
  var url = null;
  var queries = null;
  var split = null;
  var searchObject = {};
  var i = 0;
  var qaTestsCookie;
  var qaEnvironment;
  var qaFlagsCookie;
  var qaSurveyId;

  if (navigator.cookieEnabled) {
    if (currentSessionLong) {
      // QA Config
      qa = {
        enable: false,
        icon: true,
        style: true,
        expires: 2 * 60,
      };
      if (ninjaConfig && ninjaConfig.laquesisQa) {
        if (ninjaConfig.laquesisQa.enable) {
          qa.enable = Boolean(ninjaConfig.laquesisQa.enable);
        }
        if (ninjaConfig.laquesisQa.icon) {
          qa.icon = Boolean(ninjaConfig.laquesisQa.icon);
        }
        if (ninjaConfig.laquesisQa.style) {
          qa.style = Boolean(ninjaConfig.laquesisQa.style);
        }
        if (ninjaConfig.laquesisQa.expires) {
          qa.expires = parseInt(ninjaConfig.laquesisQa.expires.toString(), 10);
        }
        if (ninjaConfig.laquesisQa.experiments) {
          qa.experiments = ninjaConfig.laquesisQa.experiments;
        }
        if (ninjaConfig.laquesisQa.flags) {
          qa.flags = ninjaConfig.laquesisQa.flags;
        }
      }

      // Set userId on the init
      if (ninjaConfig && ninjaConfig.userId) {
        userId = ninjaConfig.userId;
      }

      try {
        queries = window.location.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i = i + 1) {
          split = queries[i].split('=');
          searchObject[split[0]] = split[1];
        }

        if (undefined !== searchObject.laquesisqahash) {
          split = atob(deucex(searchObject.laquesisqahash)).split('!');
          if (split.length <= 4) {
            qaTestsCookie = split[0];
            qaEnvironment = split[1];
            qaFlagsCookie = split[2];
            qaSurveyId = split[3];

            //  Apply hash if:
            //  - ninja env is NOT production -> we don't care what is applied
            //  - ninja env IS production AND hash env IS also production
            if (ninjaConfig.environment !== 'production' || (ninjaConfig.environment === 'production' && qaEnvironment === 'production')) {
              if (qaTestsCookie) {
                // Fixed list of experiments
                localTestsCookieValue = qaTestsCookie.toLowerCase();
                // Save in the cookie to enable backend experiments
                cookieStorage.set(cookieName, localTestsCookieValue, {
                  expires: getCookieExpirationDate(qa.expires, 'min'),
                  path: '/',
                  domain: ninjaConfig.cookieDomain,
                });
              }

              if (qaFlagsCookie) {
                // Fixed list of flags
                localFlagsCookieValue = qaFlagsCookie.toLowerCase();
                // Save in the cookie to enable backend flags
                cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
                  expires: getCookieExpirationDate(qa.expires, 'min'),
                  path: '/',
                  domain: ninjaConfig.cookieDomain,
                });
              }

              if (qaSurveyId) {
                isLaquesisQa = true;

                // show survey with timeout
                getSurveyData(qaSurveyId, readEventsForHash);
              }

              if (qaTestsCookie || qaFlagsCookie || qaSurveyId) {
                // qaMode true so no refresh
                qaMode = true;
                // Disable the QA menu
                qa.enable = false;
                // Flag that it is in QAMODE so the refresh will not work
                cookieStorage.set(cookieNameQa, 1, {
                  expires: getCookieExpirationDate(qa.expires, 'min'),
                  path: '/',
                  domain: ninjaConfig.cookieDomain,
                });
              }
            }
          }
        }
      } catch (e) {
        return false;
      }

      if (ninjaConfig.environment !== 'production') {
        if (qa.enable === true) {
          if (undefined !== qa.experiments) {
            // Fixed list of experiments
            localTestsCookieValue = qa.experiments.toLowerCase();
            // Save in the cookie to enable backend experiments
            cookieStorage.set(cookieName, localTestsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });
            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
          if (undefined !== qa.flags) {
            // Fixed list of flags
            localFlagsCookieValue = qa.flags.toLowerCase();
            // Save in the cookie to enable backend flags
            cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });

            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
        }
      }

      if (cookieStorage.get(cookieNameQa)) {
        // If the laquesisqa is still there

        // show survey in QA mode
        isLaquesisQa = true;

        // do not refresh
        qaMode = true;

        // Disable the QA menu
        qa.enable = false;
      }

      // Read the definition only ones
      if (!localTestsCookieValue) {
        localTestsCookieValue = cookieStorage.get(cookieName);
      }
      if (!localFlagsCookieValue) {
        localFlagsCookieValue = cookieStorage.get(cookieNameFf);
        if (!localFlagsCookieValue) {
          localFlagsCookieValue = cookieStorage.get('laquesis_ff');
        }
      }
      if (!localSurveysCookieValue) {
        localSurveysCookieValue = cookieStorage.get(cookieNameSu);
      }

      // Read the status
      lqDefinitionString = cookieStorage.get(cookieNameLq);

      if (!lqDefinitionString) {
        // Refresh because there is no cookie yet
        mustRefresh = true;
      } else {
        lqDefinition = validateLqDefinition(lqDefinitionString);

        // Check the expired time
        now = getNow();
        if (lqDefinition[0] - now < 0) {
          // Refresh because the definition is expired
          mustRefresh = true;
        }

        if (lqDefinition.length === 4) {
          // Remove OLD discovery test
          if (indexOf(lqDefinition[3], '*') !== -1) {
            lqDefinition = lqDefinition.slice(0, 3);
          }
        }

        // lqDefinition[0] == Timestamp => Next Refresh
        // lqDefinition[1] == 7832af83x1123 => Current sesstion
        // lqDefinition[2] == exp-1#exp-2 => Tracked experiments in this session
        // lqDefinition[3] == Timestamp => Next survey show
        // lqDefinition[4] == survey_id1#survey_id2 => Showed surveys
      }

      if (mustRefresh && qaMode === false) {
        url = SPLITTER_CDN + '/assign?';
        url += 'sl=' + currentSessionLong;
        if (userId) {
          if (typeof userId === 'string') {
            userId = userId.toLowerCase();
          }
          url += '&ui=' + userId;
        }
        url += '&cc=' + hydraConfig.params.cC + '&ch=';

        if (ninjaConfig.platform === 'm') {
          url += 'm';
        } else if (ninjaConfig.platform === 'd') {
          url += 'd';
        } else {
          url += 'w';
        }

        if (undefined !== hydraConfig.params.bR) {
          url += '&br=' + hydraConfig.params.bR;
        } else {
          url += '&br=olx';
        }
        ajaxCall('get', url, processDefinition, null, function (error) {
          trackError('fetchDefinitionsError', 'Laquesis', 'processDefinition', error);
        });
      } else {
        if (localTestsCookieValue) {
          parseLaquesisCookie(localTestsCookieValue);

          if (cookieFromLq) {
            // New user, the cookie and definition comes from the backend
            // cookieFromLq = false; // TODO:
            // Fix for some servers that quotes the cookie when contains @ :: Overwrite laquesis without quotes
            if (localTestsCookieValue) {
              localTestsCookieValue = localTestsCookieValue.replace(/^"(.+)"$/, '$1');
              cookieStorage.set(cookieName, localTestsCookieValue, {
                expires: getCookieExpirationDate(1, 'year'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }
            trackTestsAssignment();
          }
        }

        if (localFlagsCookieValue) {
          parseLaquesisFfCookie(localFlagsCookieValue);
        }

        if (localSurveysCookieValue) {
          parseLaquesisSuCookie(localSurveysCookieValue);
        }
      }

      // Watch the laquesis cookie
      if (initPushed === false) {
        initPushed = true;

        // expose functions to `window`
        setupWindow();

        // FIXME: should wait for processDefinition?
        // Call function when laquesis is loaded and ready to use
        if (undefined !== window.laquesisFunctionsCallback || typeof window.laquesisFunctionsCallback === 'function') {
          window.laquesisFunctionsCallback.call(null);
        }

        // Setup laquesisResults
        window.laquesisResults = window.laquesisResults || [];

        // Overwrite function push
        currentLqPush = window.laquesisResults.push;
        window.laquesisResults.push = function (params) {
          var result = currentLqPush.apply(window.laquesisResults, [params]);

          checkResults();

          return result;
        };

        // Set internal queue
        if (!window.laquesisQueue) {
          window.laquesisQueue = [];
        }
        window.laquesisQueue.push = function (func) {
          if (typeof func === 'function') {
            func();
          }
        };
        while (window.laquesisQueue.length > 0) {
          window.laquesisQueue.push(window.laquesisQueue.shift());
        }

        checkResults();
      }

      // FIXME: why we need this to run every 5 min?
      // Check the status again every 5 minutes
      setTimeout(init, 1000 * 60 * 5);
    } else {
      // The session_long do not exists yet, retry 5 times
      if (retries < 5) {
        retries = retries + 1;
        setTimeout(init, 500);
      }
    }
  }
  return true;
}

// Expose functionality to `window`
export function setupWindow() {
  // Expose function
  window.isVariantEnabled = function (testName, variantName, delayTracking, trackOrder) {
    return isVariantEnabled(testName, variantName, delayTracking, trackOrder);
  };

  // Expose function
  window.getLaquesisVariant = function (testName, delayTracking, trackOrder) {
    return getLaquesisVariant(testName, delayTracking, trackOrder);
  };

  // Expose function
  window.isFeatureEnabled = function (flagName) {
    return isFeatureEnabled(flagName);
  };

  // Expose function
  window.laquesisSetUserId = function (id) {
    return laquesisSetUserId(id);
  };

  // Expose function
  window.laquesisDropUserId = function () {
    return laquesisDropUserId();
  };

  // Expose function
  window.laquesisShowSurvey = function (surveyId) {
    isLaquesisQa = false;
    return showSurvey(surveyId);
  };

  // Expose function
  window.isSurveyAvailable = function (surveyId) {
    return isSurveyAvailable(surveyId);
  };

  if (ninjaConfig.environment !== 'production') {
    if (qa.enable === true) {
      // Call laquesisqa.js as external file to do not load it on production
      loadScript(LAQUESIS_QA_URL, 'laquesisqa', function () {
        loadScript(JSQR_URL, 'laquesisqr', function () {
          // @ts-ignore
          window.laquesisqa(
            {
              listOfActiveTests: listOfActiveTests,
              listOfActiveFlags: listOfActiveFlags,
              qa: qa,
              params: hydraConfig.params,
            },
            ninjaConfig
          );
        });
      });
    }

    // Expose function
    window.laquesisShowSurveyByLink = function (link) {
      isLaquesisQa = true;
      return showSurvey(0, link);
    };
  } else if (ninjaConfig.siteUrl === 'www.console-stg.data.olx.org' || ninjaConfig.siteUrl === 'www.console.data.olx.org') {
    // Expose function
    window.laquesisShowSurveyByLink = function (link) {
      isLaquesisQa = true;
      return showSurvey(0, link);
    };
  }

  window.laquesisGetActive = function () {
    // return copy of current state
    return {
      experiments: jsonDecode(jsonEncode(listOfActiveTests)),
      flags: jsonDecode(jsonEncode(listOfActiveFlags)),
    };
  };
}

// Collect the data from the API
export function processDefinition(newDefinition) {
  var config = null;
  var newCookieValue;
  var newFfCookieValue;
  var newSuCookieValue;
  var now = null;
  var testCookie = null;

  if (newDefinition) {
    if (newDefinition === '') {
      // this is tracked in error handler of the previous call
      // trackError('fetchDefinitionsError', 'Laquesis', 'processDefinition', 'Unable to connect');
    }
    config = jsonDecode(newDefinition);
  }

  if (config) {
    newCookieValue = parseTestsJson(config.tests);
    newFfCookieValue = parseFlagsJson(config.flags);
    newSuCookieValue = parseSurveysJson(config.surveys);

    // Set the next refresh
    now = getNow();
    lqDefinition[0] = now + parseInt(config.config.next_update_foreground_in_minutes, 10) * 60;
    cookieStorage.set(cookieNameLq, lqDefinition.join('|'), {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: ninjaConfig.cookieDomain,
    });

    // Track and store the assignment if it is different
    if (localTestsCookieValue !== newCookieValue) {
      // Save the new cookie value
      localTestsCookieValue = newCookieValue;
      cookieStorage.set(cookieName, newCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackTestsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_ASSIGNMENT, { experiments: newCookieValue });
    }

    // Store the flags if it is different
    if (localFlagsCookieValue !== newFfCookieValue) {
      // Save the new cookie value
      localFlagsCookieValue = newFfCookieValue;
      testCookie = cookieStorage.get('laquesis_ff');
      if (testCookie) {
        // Remove old cookie
        cookieStorage.remove('laquesis_ff', {
          path: '/',
          domain: ninjaConfig.cookieDomain,
        });
      }
      cookieStorage.set(cookieNameFf, newFfCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackFlagsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_FLAG_ASSIGNMENT, { flags: newFfCookieValue });
    }

    // Store the surveys if it is different
    if (localSurveysCookieValue !== newSuCookieValue) {
      // Save the new cookie value
      localSurveysCookieValue = newSuCookieValue;
      cookieStorage.set(cookieNameSu, newSuCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });
    }

    // Call function when assign is loaded and ready to use. @deprecated
    if (undefined !== window.laquesisAssignCallback || typeof window.laquesisAssignCallback === 'function') {
      window.laquesisAssignCallback.call(null);
    }

    triggerCustomEvent(EVENTS.LAQUESIS_READY, null, document);
  }
}

export function parseTestsJson(tests) {
  var i;
  var result = '';

  tests.sort(function compare(a, b) {
    return a.tN.localeCompare(b.tN);
  });
  listOfActiveTests = [];
  for (i = 0; i < tests.length; i += 1) {
    tests[i].tN = tests[i].tN.toLowerCase();
    tests[i].vN = tests[i].vN.toLowerCase();
    result += '#' + tests[i].tN + '@' + tests[i].vN;
    listOfActiveTests[tests[i].tN] = tests[i].vN;
  }

  return result.substr(1);
}

export function parseFlagsJson(flags) {
  var i;
  var result = '';

  flags.sort(function compare(a, b) {
    return a.tN.localeCompare(b.tN);
  });
  listOfActiveFlags = [];
  for (i = 0; i < flags.length; i += 1) {
    flags[i].tN = flags[i].tN.toLowerCase();
    result += '#' + flags[i].tN;
    listOfActiveFlags[flags[i].tN] = 1;
  }

  return result.substr(1);
}

export function parseSurveysJson(surveys) {
  var i;
  var j;
  var result = '';
  var eventName = '';
  var tmpCond = null;
  var conditions = {};
  var lqDefinitionLocal = [];
  var lqDefinitionTmp = [];
  var withLocalStorage = false;
  var json = null;

  withLocalStorage = storageAvailable();

  surveys.sort(function compare(a, b) {
    return a.id.toString().localeCompare(b.id.toString());
  });

  if (undefined !== lqDefinition[4]) {
    lqDefinitionTmp = lqDefinition[4].split('#');
  }

  listOfActiveSurveys = {};

  for (i = 0; i < surveys.length; i += 1) {
    if (surveys[i].triggers) {
      for (j = 0; j < surveys[i].triggers.length; j += 1) {
        result += '#' + surveys[i].id;

        if (surveys[i].triggers[j].event_name) {
          eventName = surveys[i].triggers[j].event_name;
          tmpCond = surveys[i].triggers[j].conditions || [];

          // TODO: Remove this part alltogether and always check withLocalStorage
          if (tmpCond.length === 0) {
            result += '@' + eventName + '|0';
          } else if (withLocalStorage) {
            result += '@' + eventName + '|1';
          }

          // save all surveys, even those with empty conditions
          if (undefined === listOfActiveSurveys[eventName]) {
            listOfActiveSurveys[eventName] = [];
          }

          if (undefined === conditions[surveys[i].id]) {
            conditions[surveys[i].id] = {};
          }

          listOfActiveSurveys[eventName].push({
            cond: tmpCond,
            id: surveys[i].id,
          });
          conditions[surveys[i].id][eventName] = tmpCond;
        }
      }
    }

    // Clean up the showed surveys that are not active anymore
    if (inArray(lqDefinitionTmp, surveys[i].id.toString())) {
      lqDefinitionLocal.push(surveys[i].id);
    }
  }

  // Store conditions by survey and event
  if (withLocalStorage) {
    json = jsonEncode(conditions);
    window.localStorage.setItem('_lq_surveys_', json);
  }

  lqDefinition[4] = lqDefinitionLocal.join('#');

  return result.substr(1);
}

// Return if the testName with that variantValue is enable or not for this user
export function isVariantEnabled(testName, variantValue, delayTracking, trackOrder) {
  var foundTest = false;
  var testNameLower = testName.toLowerCase();
  var variantValueLower = variantValue.toLowerCase();

  if (!navigator.cookieEnabled) {
    return false;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    if (listOfActiveTests[testNameLower] === variantValueLower) {
      foundTest = true;
      trackImpression(testNameLower, variantValueLower, delayTracking, trackOrder);
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
        experiment: testNameLower,
        variant: variantValueLower,
        isDelayedTracking: delayTracking,
        trackOrder: trackOrder,
      });
    }
  }

  return foundTest;
}

// Return the variant for testName or null
export function getLaquesisVariant(testName, delayTracking, trackOrder) {
  var variantValue = null;
  var testNameLower = testName.toLowerCase();

  if (!navigator.cookieEnabled) {
    return null;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    variantValue = listOfActiveTests[testNameLower];
    trackImpression(testNameLower, variantValue, delayTracking, trackOrder);
    triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
      experiment: testNameLower,
      variant: variantValue,
      isDelayedTracking: delayTracking,
      trackOrder: trackOrder,
    });
    return variantValue;
  }

  return null;
}

// Return if the flagName is enable or not for this user
export function isFeatureEnabled(flagName) {
  if (!navigator.cookieEnabled) {
    return false;
  }

  return undefined !== listOfActiveFlags[flagName.toLowerCase()];
}

// Set userId
export function laquesisSetUserId(id) {
  userId = id;
}

// Drop userId
export function laquesisDropUserId() {
  userId = null;
}

// Entry function - Parse the data in the array
export function checkResults() {
  var index;
  var data;

  function c(cookieValue) {
    parseLaquesisCookie(cookieValue);
  }

  for (index in window.laquesisResults) {
    if (Object.prototype.hasOwnProperty.call(window.laquesisResults, index)) {
      data = window.laquesisResults[index];
      if (typeof data === 'object' && undefined === data.processed) {
        if (undefined !== data.newResult) {
          // Queue the call to the function
          window.laquesisResults[index].processed = true;
          window.laquesisQueue.push(c(data.newResult));
        }
      }
    }
  }
}

// Track the tests assignment
export function trackTestsAssignment(delayTracking, trackOrder) {
  var key;
  var testDefinition = '';

  for (key in listOfActiveTests) {
    if (Object.prototype.hasOwnProperty.call(listOfActiveTests, key)) {
      testDefinition += ',"' + key + ',' + listOfActiveTests[key] + '"';
    }
  }
  testDefinition = '[' + testDefinition.substr(1) + ']';

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: ['test_assignment'],
      test_definition: testDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: ['test_assignment'],
      test_definition: testDefinition,
    });
  }
}

// Track the flags assignment
export function trackFlagsAssignment(delayTracking, trackOrder) {
  var key;
  var flagDefinition = '';

  for (key in listOfActiveFlags) {
    if (Object.prototype.hasOwnProperty.call(listOfActiveFlags, key)) {
      flagDefinition += ',"' + key + '"';
    }
  }
  flagDefinition = '[' + flagDefinition.substr(1) + ']';

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: ['flag_assignment'],
      flag_definition: flagDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: ['flag_assignment'],
      flag_definition: flagDefinition,
    });
  }
}

// Track the impression only one time per session per test
export function trackImpression(testName, variantValue, delayTracking, trackOrder) {
  var i;
  var trackedTests;
  var foundTrackedTest = false;
  var params = {};

  if (undefined === lqDefinition[1] || lqDefinition[1] !== currentSession) {
    // If there is no session or is different, start from 0
    lqDefinition[1] = currentSession;
    lqDefinition[2] = ''; // Tracked tests
  } else {
    // If the session is the same, find the test
    if (undefined !== lqDefinition[2]) {
      trackedTests = lqDefinition[2].split('#');
      for (i = 0; i < trackedTests.length; i += 1) {
        if (trackedTests[i] === testName) {
          foundTrackedTest = true;
          break;
        }
      }
    }
  }

  // Track the impression only one time per session
  if (!foundTrackedTest) {
    // Add the test and save the cookie
    if (undefined === lqDefinition[2] || lqDefinition[2].length === 0) {
      lqDefinition[2] = testName;
    } else {
      lqDefinition[2] += '#' + testName;
    }
    cookieStorage.set(cookieNameLq, lqDefinition.join('|'), {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: ninjaConfig.cookieDomain,
    });

    // Finally track

    if (delayTracking) {
      params = {
        trackOrder: trackOrder,
        trackEvent: ['test_impression'],
        test_name: testName,
        test_variation: variantValue,
      };

      // Once user has interacted with the page vitals are out of scope -> do not send them anymore
      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayerDelayed.push(params);
    } else {
      cleanupTracking();

      params = {
        trackEvent: ['test_impression'],
        test_name: testName,
        test_variation: variantValue,
      };

      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayer.push(params);
    }
  }
}

// Remove all the test properties
export function cleanupTracking() {
  var length = ninjaConfig.dataLayer.length;
  var i;

  for (i = 0; i < length; i = i + 1) {
    if (
      undefined !== ninjaConfig.dataLayer[i].test_definition ||
      undefined !== ninjaConfig.dataLayer[i].test_name ||
      undefined !== ninjaConfig.dataLayer[i].test_variation ||
      undefined !== ninjaConfig.dataLayer[i].flag_definition
    ) {
      ninjaConfig.dataLayer.splice(i, 1);
      i = length;
    }
  }
}

// Track
export function trackSurvey(eventName, survey_id, survey_page_id, question_id, question_value) {
  var url;
  var hydraImage;
  var params = {};

  // Finish current survey
  if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
    if (activeSurvey === true) {
      activeSurvey = false;
    }
  }

  // Path
  url = getProtocol() + getHydraHost() + hydraConfig.survey_path;

  // Properties
  params.eN = eventName;
  params.sl = currentSessionLong;
  params.s = currentSession;
  params.survey_id = survey_id;
  params.survey_page_id = survey_page_id;
  if (undefined !== question_id) {
    params.survey_question_id = question_id;
  }
  if (undefined !== question_value) {
    params.survey_question_value = question_value;
  }

  // Config values, countries and regions
  params.cC = hydraConfig.params.cC;
  params.bR = hydraConfig.params.bR;

  if (ninjaConfig.platform === 'm') {
    params.cH = ChannelName.Mobile;
  } else if (ninjaConfig.platform === 'd') {
    params.cH = ChannelName.Desktop;
  } else if (undefined !== currentPlatform) {
    params.cH = currentPlatform;
  } else {
    params.cH = 'w';
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    params.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    params.env = 'dev';
  }

  // Timestamp
  params.t = new Date().getTime();

  hydraImage = new Image();
  hydraImage.onload = function () {
    onHitSentOk();
    hydraImage.onload = null;
  };
  hydraImage.onerror = function () {
    trackError('HIT_FAIL', 'Hydra', 'trackSurvey', 'On ' + eventName);
  };
  hydraImage.src = url + '?' + objectToQueryString(params);
}

// Read and parse the test cookie
export function parseLaquesisCookie(cookie) {
  var listOfTest;
  var i;
  var nameValue;
  var valueFlag;
  var cookieValue = cookie;

  // Fix for some servers that quotes the cookie when contains @ :: Use value without quotes
  if (cookieValue) {
    cookieValue = cookieValue.replace(/^"(.+)"$/, '$1');
  }

  listOfTest = cookieValue.split('#');
  for (i = 0; i < listOfTest.length; i += 1) {
    nameValue = listOfTest[i].split('@');
    if (nameValue.length === 2) {
      valueFlag = nameValue[1].split('|');
      if (valueFlag.length === 2) {
        // Read the set-cookie from the server
        if (valueFlag[1] === 't') {
          trackImpression(nameValue[0], valueFlag[0]);
          triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
            experiment: nameValue[0],
            variant: valueFlag[0],
            isDelayedTracking: false,
            trackOrder: undefined,
          });
          if (undefined === listOfActiveTests[nameValue[0]]) {
            listOfActiveTests[nameValue[0]] = valueFlag[0];
          }
        }
      } else {
        // Read the normal cookie
        if (undefined === listOfActiveTests[nameValue[0]]) {
          listOfActiveTests[nameValue[0]] = valueFlag[0];
        }
      }
    }
  }
}

// Read and parse the flags cookie
export function parseLaquesisFfCookie(cookieValue) {
  var listOfFlags;
  var i;

  listOfFlags = cookieValue.split('#');
  for (i = 0; i < listOfFlags.length; i += 1) {
    // Read the normal cookie
    if (undefined === listOfActiveFlags[listOfFlags[i]]) {
      listOfActiveFlags[listOfFlags[i]] = 1;
    }
  }
}

// Read and parse the surveys cookie
export function parseLaquesisSuCookie(cookieValue) {
  var listOfSurveys;
  var i;
  var surveyValue;
  var surveyId;
  var triggers = [];
  var eventName;
  // var conditionsFlag;
  var conditions = {};
  var tmpCond = null;
  var withLocalStorage = false;
  var json = null;

  // Get all the conditions if localStorage exists
  withLocalStorage = storageAvailable();
  if (withLocalStorage) {
    json = window.localStorage.getItem('_lq_surveys_');
    if (json) {
      json = deucex(json);
      if (json) {
        conditions = jsonDecode(json);
      }
    }
  }

  listOfSurveys = cookieValue.split('#');
  for (i = 0; i < listOfSurveys.length; i += 1) {
    surveyValue = listOfSurveys[i].split('@');
    if (surveyValue.length === 2) {
      surveyId = surveyValue[0];
      triggers = surveyValue[1].split('|');
      if (triggers.length > 1) {
        eventName = triggers[0];
        // conditionsFlag = triggers[1];

        if (withLocalStorage) {
          if (undefined === listOfActiveSurveys[eventName]) {
            listOfActiveSurveys[eventName] = [];
          }
          if (conditions && undefined !== conditions[surveyId] && undefined !== conditions[surveyId][eventName]) {
            tmpCond = conditions[surveyId][eventName];
          } else {
            tmpCond = [];
          }

          listOfActiveSurveys[eventName].push({
            cond: tmpCond,
            id: surveyId,
          });
        }
        // if (conditionsFlag === '0') {
        //   if (undefined === listOfActiveSurveys[eventName]) {
        //     listOfActiveSurveys[eventName] = [];
        //   }
        //   listOfActiveSurveys[eventName].push({
        //     cond: [],
        //     id: surveyId
        //   });
        // } else if (withLocalStorage) {
        //   if (undefined === listOfActiveSurveys[eventName]) {
        //     listOfActiveSurveys[eventName] = [];
        //   }
        //   if (undefined !== conditions[surveyId] && undefined !== conditions[surveyId][eventName]) {
        //     tmpCond = conditions[surveyId][eventName];
        //   }
        //   listOfActiveSurveys[eventName].push({
        //     cond: tmpCond,
        //     id: surveyId
        //   });
        // }
      }
    }
  }
}

// Check if support localStorage
export function storageAvailable() {
  var storage;
  try {
    var x = '__storage_test__';
    storage = window.localStorage;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

// Entry function - Track a page
export function trackPage(params) {
  var eventData = [];
  var eventName;
  var newValues;
  var i;

  onHitSentOk();
  if (initPushed === true) {
    // eventName, (trackPage) and eventType
    if (params.eventData.category) {
      if (params.eventData.category) {
        eventData.push(params.eventData.category);
      }
      if (params.eventData.action) {
        eventData.push(params.eventData.action);
      }
      if (params.eventData.label) {
        eventData.push(params.eventData.label);
      }
      if (params.eventData.value) {
        eventData.push(params.eventData.value);
      }

      eventName = eucex(convertString(eventData.join('_')));
      newValues = makeMapping('H', 'trackEvent', eventName);
      if (newValues.key === false || newValues.value === false) {
        return false;
      }
      eventName = newValues.value;
    }
    if (params.pageName) {
      // Add original trackPage
      newValues = makeMapping('H', 'trackPage', params.pageName);
      if (newValues.key === false || newValues.value === false) {
        return false;
      }
      eventName = eucex(newValues.value);
    }

    if (undefined !== listOfActiveSurveys[eventName]) {
      for (i = 0; i < listOfActiveSurveys[eventName].length; i += 1) {
        if (checkParams(params, listOfActiveSurveys[eventName][i].cond)) {
          isLaquesisQa = false;
          showSurvey(listOfActiveSurveys[eventName][i].id);
        }
      }
    }
  } else {
    // If Laquesis is not started, retry 5 times every half second
    if (trackPageRetries < 5) {
      trackPageRetries = trackPageRetries + 1;
      setTimeout(function () {
        trackPage(params);
      }, 500);
      return false;
    }
  }

  return true;
}

// Entry function - Track an event
export function trackEvent(params) {
  return trackPage(params);
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

// Internal function - Callback after finish one hit
export function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}

/**
 * validate string consistency
 * @param defString {string}
 * @returns {string[]}
 */
export function validateLqDefinition(defString) {
  // module.lqDefinition[0] == Timestamp => Next Refresh
  // module.lqDefinition[1] == 7832af83x1123 => Current session
  // module.lqDefinition[2] == exp-1#exp-2 => Tracked experiments in this session
  // module.lqDefinition[3] == Timestamp => Next survey show
  // module.lqDefinition[4] == survey_id1#survey_id2 => Showed surveys

  var parts = defString.split('|');

  // discard anything unexpected
  if (undefined === parts) {
    parts = [];
  } else if (parts.length > 5) {
    parts = parts.slice(0, 5);
  }

  // validate each part
  if (undefined === parts[0]) {
    parts[0] = '';
  } else if (!parts[0].match(/\d{10}/)) {
    parts[0] = '';
  }

  // do not validate parts[1]
  if (undefined === parts[1]) {
    parts[1] = '';
  }

  // JIRA_REGEX = /^[A-Z]{1}[A-Z\d]{0,9}-\d+$/ - we use lowecase here
  if (undefined === parts[2]) {
    parts[2] = '';
  } else if (!parts[2].match(/^([a-z]{1}[a-z\d]{0,9}-\d+#?)+$/)) {
    parts[2] = '';
  }

  if (undefined === parts[3]) {
    parts[3] = '';
  } else if (!parts[3].match(/\d{10}/)) {
    parts[3] = '';
  }

  if (undefined === parts[4]) {
    parts[4] = '';
  } else if (!parts[4].match(/^(\d+#?)+[^#]$/)) {
    parts[4] = '';
  }

  return parts;
}

export function checkParams(params, conditions) {
  var i;
  var checkAnd = true;
  var allParams = params.customParams;
  var defaultParams = getDefaultParams(params);

  // Add identifiers
  allParams.sl = params.sessionParams.sessionLong;
  allParams.s = params.sessionParams.session;
  allParams.cl = params.sessionParams.sessionCountLong;
  allParams.c = params.sessionParams.sessionCount;
  allParams.cp = deucex(getCurrentPath());
  allParams.cc = defaultParams.cC;
  allParams.br = defaultParams.bR;

  if (!conditions) {
    return false;
  }

  for (i = 0; i < conditions.length; i += 1) {
    if (checkAnd && checkOR(allParams, conditions[i])) {
      checkAnd = true;
    } else {
      checkAnd = false;
    }
  }

  return checkAnd;
}

export function checkOR(params, conditions) {
  var check = false;
  var paramValue;
  var i;

  for (i = 0; i < conditions.length; i += 1) {
    // Get the value using cookies or properties
    if (undefined !== conditions[i].c && Boolean(conditions[i].c)) {
      paramValue = cookieStorage.get(conditions[i].n);
    } else {
      paramValue = params[conditions[i].n];
    }
    if (conditions[i].o === 'is_null') {
      if (undefined === paramValue || paramValue === null) {
        check = true;
      }
    } else if (undefined !== paramValue && paramValue !== null) {
      // operator
      switch (conditions[i].o) {
        case 'equals':
          if (paramValue.toString() === conditions[i].v.toString()) {
            check = true;
          }
          break;

        case 'not_equals':
          if (paramValue.toString() !== conditions[i].v.toString()) {
            check = true;
          }
          break;

        case 'is_not_null':
          if (paramValue !== null) {
            check = true;
          }
          break;

        // TODO: Why parsing to int? what about floats?
        case 'greater_than':
          if (parseInt(paramValue, 10) > parseInt(conditions[i].v, 10)) {
            check = true;
          }
          break;

        case 'less_than':
          if (parseInt(paramValue, 10) < parseInt(conditions[i].v, 10)) {
            check = true;
          }
          break;

        default:
          break;
      }
    }
  }

  return check;
}

// Return if this surveys is ready to be shown for this user
export function isSurveyAvailable(surveyId) {
  var i;
  var event;
  var found = false;

  for (event in listOfActiveSurveys) {
    if (Object.prototype.hasOwnProperty.call(listOfActiveSurveys, event)) {
      for (i = 0; i < listOfActiveSurveys[event].length; i += 1) {
        if (listOfActiveSurveys[event][i].id.toString() === surveyId.toString()) {
          found = true;
        }
      }
    }
  }

  // Check if the surveys was already shown
  if (found && !isSurveyShown(surveyId)) {
    return true;
  }

  return false;
}

export function isSurveyShown(surveyId, action) {
  var i;
  var definition = [];
  var found = false;

  // Check if the surveys was already shown
  if (undefined !== lqDefinition[4]) {
    if (lqDefinition[4] !== '') {
      definition = lqDefinition[4].split('#');
    }
  }

  for (i = 0; i < definition.length; i += 1) {
    if (definition[i].toString() === surveyId.toString()) {
      found = true;
    }
  }

  if (action === 'add' && found === false) {
    definition.push(surveyId);
    lqDefinition[4] = definition.join('#');
    cookieStorage.set(cookieNameLq, lqDefinition.join('|'), {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: ninjaConfig.cookieDomain,
    });
  }

  return found;
}

export function getSurveyData(surveyId, callback, link) {
  loadScript(LAQUESIS_SURVEY_URL, 'laquesisSurvey', function () {
    var suburl;
    var params = {};

    if (link !== undefined && link !== null) {
      suburl = link;
    } else {
      suburl = SPLITTER_CDN + '/survey';
      params.id = surveyId;
      params.cc = hydraConfig.params.cC;
      params.br = hydraConfig.params.bR;

      if (ninjaConfig.platform === 'm') {
        params.ch = 'm';
      } else if (ninjaConfig.platform === 'd') {
        params.ch = 'd';
      } else {
        params.ch = 'w';
      }
    }
    ajaxCall('get', suburl + '?' + objectToQueryString(params), callback);
  });
}

export function showSurvey(surveyId, link) {
  var now;
  var surveyLink = link;

  now = getNow();

  if (!isLaquesisQa) {
    // Check if the surveys was already shown
    if (!isSurveyAvailable(surveyId)) {
      return;
    }

    surveyLink = null;
  }

  if (activeSurvey === false) {
    activeSurvey = true;
  } else {
    // Another survey is running
    return;
  }

  counter = now;

  getSurveyData(surveyId, renderSurvey, surveyLink);
}

export function readEventsForHash(jsonSurveyData) {
  var surveyData;
  var newSuCookieValue;

  if (jsonSurveyData === null || jsonSurveyData === '') {
    trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
    return;
  }

  surveyData = jsonDecode(jsonSurveyData);
  newSuCookieValue = parseSurveysJson([surveyData]);

  // Store the surveys if it is different
  if (localSurveysCookieValue !== newSuCookieValue) {
    // Save the new cookie value
    localSurveysCookieValue = newSuCookieValue;
    cookieStorage.set(cookieNameSu, newSuCookieValue, {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: ninjaConfig.cookieDomain,
    });
  }
}

export function renderSurvey(jsonSurveyData) {
  var surveyData;
  var nextSurvey = counter;
  var delayRender = 3;
  var now;

  if (jsonSurveyData === null || jsonSurveyData === '') {
    trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
    return;
  }

  surveyData = jsonDecode(jsonSurveyData);

  if (surveyData.config) {
    if (undefined !== surveyData.config.next_survey_allowed_in_sec) {
      nextSurvey = surveyData.config.next_survey_allowed_in_sec;
    }
    if (undefined !== surveyData.config.delay_render_in_sec) {
      delayRender = surveyData.config.delay_render_in_sec;
    }

    // Render with delay
    // if (!isLaquesisQa) {
    now = getNow();
    if (now - counter < delayRender) {
      // Check again every half second
      setTimeout(function () {
        renderSurvey(jsonSurveyData);
      }, 500);
      return;
    }
    // }

    // Use altered `trackSurvey` callback when we are in QA mode
    if (isLaquesisQa) {
      window.laquesisSurvey(surveyData, function (eventName) {
        // Finish current survey
        if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
          if (activeSurvey === true) {
            activeSurvey = false;
          }
        }
      });
    } else {
      window.laquesisSurvey(surveyData, trackSurvey);
    }

    if (isLaquesisQa) {
      isLaquesisQa = false; // reset QA mode
    } else {
      // Store the next time to show another survey
      lqDefinition[3] = now + nextSurvey;

      // Mark this survey as shown
      isSurveyShown(surveyData.id, 'add');
    }
  }
}

export function getIdentifier() {
  return userId || currentSessionLong;
}
