import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { Trackers, trackingMap } from '../const';
import { cookieStorage, getCookieName } from '../cookies';
import { eucex, getCurrentPath, getProtocol, inArray, objectToQueryString } from '../utils';
import { checkIfDebugEligible, generateSession, randomFactor } from './session';
import trackers from './trackers';
import { getEventData, getHash, getHost, getHydraHost, getInvite, getPageName, getReferrer } from './utils';

// need this way of structure, because we invoke track methods by property name `ninja[trackMethod]`
var ninja = {};

ninja.trackers = trackers;

// Link events
ninja.linkCallBack = null;
ninja.linkCount = 0;
ninja.linkTotalCount = 0;
ninja.linkSetTimeout = null;
ninja.linkEventName = null;
ninja.trackerList = null;
ninja.pluginList = null;

export var currentSessionLong = null;
export var currentSession = null;
export var cookieFromLq = false;
export var currentTracker = 'start';

// Entry function - Parse the data in the dataLayer
ninja.checkParam = function () {
  var index;
  var data;
  var i;
  var functionName;

  /**
   *
   * @param {String} fn - Function to invoke
   * @param {String} parameter - Function parameter
   * @param {Record<string, any>} data - user parameters
   */
  function c(fn, parameter, data) {
    ninja[fn](parameter, data);
  }

  for (index in ninjaConfig.dataLayer) {
    if (Object.prototype.hasOwnProperty.call(ninjaConfig.dataLayer, index)) {
      data = ninjaConfig.dataLayer[index];
      if (typeof data === 'object' && undefined === data.processed) {
        for (i in ninjaConfig.specialNames) {
          if (Object.prototype.hasOwnProperty.call(ninjaConfig.specialNames, i)) {
            functionName = ninjaConfig.specialNames[i];
            if (functionName !== 'processed' && data[functionName]) {
              // Queue the call to the function
              ninjaConfig.dataLayer[index].processed = true;
              window.trackingQueue.push(c(functionName, data[functionName], data));
            }
          }
        }
      }
    }
  }
};

// Push function - Tracking a page view
ninja.trackPage = function (pageName, eventData) {
  var index;
  var length;
  var trackerList;
  var tracker;
  var params;

  try {
    params = getParams(pageName, null, eventData);

    trackerList = getTrackerList();
    length = trackerList.length;

    for (index = 0; index < length; index++) {
      tracker = trackingMap[trackerList[index]];
      currentTracker = tracker;

      ninja.trackers[tracker].trackPage(params);
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'trackPage', error);
  }
};

// Push function - Tracking an event
ninja.trackEvent = function (params, eventData) {
  var index;
  var length;
  var trackerList;
  var tracker;
  var ninjaParams;
  var eventParams = params;

  try {
    // Fix for send trackEvent as string instead of Array
    if (typeof eventParams === 'string') {
      eventParams = [eventParams];
    }

    ninjaParams = getParams(null, eventParams, eventData);

    trackerList = getTrackerList();
    length = trackerList.length;

    for (index = 0; index < length; index++) {
      tracker = trackingMap[trackerList[index]];
      currentTracker = tracker;
      ninja.trackers[tracker].trackEvent(ninjaParams);
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'trackEvent', error);
  }
};

// Push function - Tracking a link event
ninja.trackLinkEvent = function (eventParams, eventData) {
  var index;
  var length;
  var trackerList;
  var tracker;
  var params;

  try {
    params = getParams(null, eventParams, eventData);

    if (ninja.linkCallBack !== null) {
      return;
    }
    if (undefined === params.customParams.linkCallBack || typeof params.customParams.linkCallBack !== 'function') {
      return;
    }
    ninja.linkCallBack = params.customParams.linkCallBack;
    ninja.linkEventName = eventParams;
    ninja.linkCount = 0;
    ninja.linkTotalCount = 0;
    delete params.customParams.linkCallBack;

    trackerList = getTrackerList();
    length = trackerList.length;

    // First count how many
    ninja.linkTotalCount = length;
    // Second trigger the track
    for (index = 0; index < length; index++) {
      tracker = trackingMap[trackerList[index]];
      currentTracker = tracker;
      ninja.trackers[tracker].trackLinkEvent(params);
    }

    ninja.linkSetTimeout = setTimeout(function () {
      ninja.linkCount = ninja.linkTotalCount;
      ninja.linkCallBack.call(null, ninja.linkEventName);
      ninja.linkTotalCount = 0;
      ninja.linkCallBack = null;
    }, 1000);
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'trackLinkEvent', error);
  }
};

// Push function - Cleanup dataLayer
ninja.cleanup = function (callBack) {
  var i;
  var length;
  var continueRunning = true;

  try {
    while (continueRunning) {
      continueRunning = false;
      length = ninjaConfig.dataLayer.length;
      for (i = 0; i < length; i = i + 1) {
        if (
          undefined === ninjaConfig.dataLayer[i].event ||
          (ninjaConfig.dataLayer[i].event.slice(0, 4) !== 'gtm.' && undefined !== ninjaConfig.dataLayer[i]['gtm.uniqueEventId'])
        ) {
          if (
            typeof ninjaConfig.dataLayer[i] === 'object' &&
            undefined === ninjaConfig.dataLayer[i].call &&
            undefined === ninjaConfig.dataLayer[i].dynx_itemid && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_totalvalue && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_pagetype // Fix for RO
          ) {
            ninjaConfig.dataLayer.splice(i, 1);
            i = length;
            continueRunning = true;
          }
        }
      }
    }
    if (typeof callBack === 'function') {
      callBack.call();
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'cleanup', error);
  }
};

// Push function - GTM event
ninja.event = function (eventName) {
  try {
    if (ninjaConfig.callBack) {
      ninjaConfig.callBack.call(ninja, eventName);
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'event', error);
  }
};

// Get the trackers list
export function getTrackerList() {
  var key;
  if (ninja.trackerList === null) {
    if (ninjaConfig.custom !== false && ninjaConfig.environment !== 'production') {
      ninja.trackerList = ['hydra'];
      for (key in ninjaConfig.custom) {
        if (Object.prototype.hasOwnProperty.call(ninjaConfig.custom, key)) {
          if (trackers[key] && trackers[key].trackPage) {
            ninja.trackerList.push(key);
          }
        }
      }
    } else {
      if (undefined !== regionConfig.custom[ninjaConfig.siteUrl].trackers) {
        ninja.trackerList = [Trackers.H].concat(regionConfig.custom[ninjaConfig.siteUrl].trackers);
      } else {
        ninja.trackerList = [Trackers.H].concat(regionConfig.trackers);
      }
    }

    // For surveys in Laquesis
    if (inArray(getPluginList(), Trackers.LQ)) {
      ninja.trackerList.push(Trackers.LQ);
    }
  }

  return ninja.trackerList;
}

// Get the plugins list
export function getPluginList() {
  var key;
  if (!ninja.pluginList) {
    if (ninjaConfig.plugins && ninjaConfig.environment !== 'production') {
      ninja.pluginList = [];
      for (key in ninjaConfig.plugins) {
        if (Object.prototype.hasOwnProperty.call(ninjaConfig.plugins, key)) {
          ninja.pluginList.push(ninjaConfig.plugins[key]);
        }
      }
    } else {
      if (regionConfig.custom[ninjaConfig.siteUrl].plugins) {
        ninja.pluginList = [].concat(regionConfig.custom[ninjaConfig.siteUrl].plugins);
      } else {
        ninja.pluginList = [].concat(regionConfig.plugins);
      }
    }
  }
  return ninja.pluginList;
}

// Collect the finish call from the trackers
export function collectCalls() {
  ninja.linkCount = ninja.linkCount + 1;
  if (ninja.linkCount === ninja.linkTotalCount) {
    ninja.linkCallBack.call(null, ninja.linkEventName);
    ninja.linkCallBack = null;
    ninja.linkTotalCount = 0;
    clearTimeout(ninja.linkSetTimeout);
  }
}

// Get custom params
export function getCustomParams(eventData) {
  // Custom params
  // All the pair:value from dataLayer except the special words
  var key;
  var subKey;
  // if propagation is disabled, use only the current event data to construct custom params. Else use all previous events
  var eventsToCheck = ninjaConfig.disablePropertyPropagation ? [eventData] : ninjaConfig.dataLayer;
  var customParams = {};

  for (key in eventsToCheck) {
    if (Object.prototype.hasOwnProperty.call(eventsToCheck, key)) {
      for (subKey in eventsToCheck[key]) {
        if (Object.prototype.hasOwnProperty.call(eventsToCheck[key], subKey)) {
          if (
            !inArray(ninjaConfig.specialNames, subKey) &&
            subKey.slice(0, 4) !== 'gtm.' &&
            !inArray(['bind', 'push', 'prototype', 'eventTimeout'], subKey)
          ) {
            customParams[subKey] = eventsToCheck[key][subKey];
          }
        }
      }
    }
  }

  return customParams;
}

/**
 * Get list of default properties shared between trackers
 * @param {Record<string, any>} params Tracking params
 * @returns {Record<string, any>}
 */
export function getDefaultParams(params) {
  var defaultParams = {};

  defaultParams.cC = hydraConfig.params.cC;
  defaultParams.bR = hydraConfig.params.bR;

  // Matrix Version
  if (regionConfig.version !== undefined) {
    defaultParams.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    defaultParams.env = 'dev';
  }

  // eventType
  if (params.eventData && params.eventData.category) {
    defaultParams.tN = 'e';
  } else if (params.pageName) {
    defaultParams.tN = 'p';
  }

  return defaultParams;
}

// Manage the session cookie
export function getSessionParams() {
  var date;
  var now;
  var session;
  var sessionLong;
  var sessionCount;
  var sessionCountLong;
  var sessionExpired;
  var sessionExtra;
  var sessionValues;
  var cookieName;
  var cookieValue;
  var noCookie = false;
  var sessionParams;
  var isSessionChange = false;

  var debugInfo = [];

  debugInfo.push('Retrieving session params');

  try {
    if (navigator.cookieEnabled) {
      debugInfo.push('Cookies enabled. Checking onap cookie');
      date = new Date();
      now = Math.round(date.getTime() / 1000);
      cookieName = getCookieName('onap');
      sessionValues = (cookieStorage.get(cookieName) || '').match(/([a-z0-9]+)-([0-9]+)-([a-z0-9]+)-([0-9]+)-([0-9]+)-?(.*)?/);
      debugInfo.push('Cookie value (onap): ' + sessionValues);
      if (sessionValues && sessionValues.length > 0) {
        sessionLong = sessionValues[1];
        sessionCountLong = parseInt(sessionValues[2], 10);
        session = sessionValues[3];
        sessionCount = parseInt(sessionValues[4], 10);
        sessionExpired = sessionValues[5];
        sessionExtra = sessionValues[6] || null;

        if (sessionExpired - now > 0) {
          sessionCount = sessionCount + 1;
        } else {
          debugInfo.push('Session expired. Generating a new session');
          sessionCountLong = sessionCountLong + 1;
          session = generateSession();
          sessionCount = 1;
        }
      } else {
        // Check if there is a lqonap cookie
        sessionValues = cookieStorage.get(getCookieName('lqonap'));
        debugInfo.push('Cookie value (lqonap): ' + sessionValues);
        if (sessionValues && sessionValues.length > 0) {
          // Use the laquesis session_long
          sessionLong = sessionValues;
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
          cookieFromLq = true;
        } else {
          debugInfo.push('Cookie not found. Generating a new sessionLong');
          // New user, create new session_long
          sessionLong = generateSession();
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
        }
      }
      sessionExpired = now + 1800;
      cookieValue = sessionLong + '-' + sessionCountLong + '-' + session + '-' + sessionCount + '-' + sessionExpired;
      if (sessionExtra) {
        cookieValue = cookieValue + '-' + sessionExtra;
      }
      cookieValue = cookieValue.replace(/[^\w\-\=]/g, '');

      cookieStorage.set(cookieName, cookieValue, {
        expires: 360,
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });
    } else {
      debugInfo.push('Cookies disabled. Generating a new sessionLong');
      sessionLong = generateSession();
      sessionCountLong = 1;
      session = sessionLong;
      sessionCount = 1;
      noCookie = true;
    }

    isSessionChange = currentSession !== null && currentSession !== session;

    // if session has changed and it's not the first one, trigger callback
    if (isSessionChange && typeof window.ninjaSessionChangedCallback === 'function') {
      window.ninjaSessionChangedCallback.apply(null, [session, currentSession]);
    }
  } catch (error) {
    debugInfo.push('Retrieving cookie data failed: ' + error.message + '. Generating a new sessionLong');
    sessionLong = generateSession();
    sessionCountLong = 1;
    session = sessionLong;
    sessionCount = 1;
    noCookie = true;
  } finally {
    // trackDebugInfo(debugInfo.join('\n'));
  }

  sessionParams = {
    sessionLong: sessionLong,
    session: session,
    sessionCountLong: sessionCountLong,
    sessionCount: sessionCount,
  };

  if (noCookie) {
    sessionParams.noCookie = noCookie;
  }

  currentSessionLong = sessionLong;
  currentSession = session;

  // update exposed window props only when session changes
  if (isSessionChange) {
    // exposeWindowObject();
  }

  return sessionParams;
}

// Collect all the data available
export function getParams(pageName, eventParams, eventData) {
  return {
    invite: getInvite(),
    host: getHost(),
    hash: getHash(),
    referrer: getReferrer(),
    pageName: getPageName(pageName),
    eventData: getEventData(eventParams),
    customParams: getCustomParams(eventData),
    sessionParams: getSessionParams(),
  };
}

// Track error
export function trackError(errorName, tracker, method, error) {
  var info;
  var url;
  var hydraImageError;

  if (error) {
    if (error.description) {
      info = error.description;
    } else if (error.message) {
      info = error.message;
    } else {
      info = error;
    }
  } else {
    info = error;
  }

  // Path
  url = getProtocol() + getHydraHost() + hydraConfig.error_path;

  // Properties
  url += '?eN=' + eucex(errorName);
  url += '&sl=' + currentSessionLong;
  if (currentSession) url += '&s=' + currentSession;
  else url += '&s=' + '00000000000' + 'x' + randomFactor;
  if (tracker) url += '&tracker=' + eucex(tracker);
  if (method) url += '&method=' + eucex(method);
  url += '&info=' + eucex(info);

  // Config values, countries and regions
  url += '&cC=' + hydraConfig.params.cC;
  url += '&bR=' + hydraConfig.params.bR;
  url += '&cH=';
  if (ninjaConfig.platform === 'm') {
    url += 'm';
  } else if (ninjaConfig.platform === 'd') {
    url += 'd';
  } else {
    url += 'w';
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    url += '&mv=' + regionConfig.version;
  }

  // Current page
  url += '&cP=' + eucex(window.location.href);

  // Environment
  if (ninjaConfig.environment !== 'production') {
    url += '&env=dev';
  }

  // Timestamp
  url += '&t=' + new Date().getTime();

  hydraImageError = new Image();

  hydraImageError.onload = function () {
    hydraImageError.onload = null;
  };
  hydraImageError.onerror = function () {
    if (errorName === 'HIT_FAIL' || errorName === 'DEBUG_INFO') {
      var hydraImageErrorBis;

      hydraImageErrorBis = new Image();
      hydraImageErrorBis.onload = function () {
        hydraImageErrorBis.onload = null;
      };

      url = url.replace('HIT_FAIL', 'HIT_ERROR_FAIL');
      url = url.replace('DEBUG_INFO', 'DEBUG_INFO_FAIL');
      hydraImageErrorBis.src = url;
    }
  };
  hydraImageError.src = url;
  return hydraImageError;
}

/**
 * Internal function - Track debug information into hydra error stream
 * @param {string} info - the text to track
 * @param {boolean} [force=false] - force the message to be logged, ignoring the configuration
 */
export function trackDebugInfo(info, force) {
  if (force || checkIfDebugEligible(currentSession)) {
    return trackError('DEBUG_INFO', undefined, undefined, info);
  }

  return undefined;
}

/**
 * Track web-vital metric to Hydra
 * The existance of performance metrics already guarantees modern browsers.
 * We can use modern functions here
 *
 * @param {PerformanceMetric} metric
 * @param {string} pageName
 * @param {(params: Record<string, any>) =>  Record<string, any>} applyPlatformFn
 * @returns
 */
export function trackWebVital(metric, pageName, applyPlatformFn) {
  var url = getProtocol() + getHydraHost() + hydraConfig.vitals_path;
  var props = {};

  if (!window.JSON || !(navigator.sendBeacon || window.fetch)) {
    return;
  }

  // Properties

  // No mapping is needed. This events/props will later be moved to be reserved words for ninja
  props.eN = 'web_vital';
  props.web_vital_name = metric.name;
  props.web_vital_value = metric.value;
  props.web_vital_rating = metric.rating;
  props.web_vital_page = pageName;

  props.sl = currentSessionLong;
  if (currentSession) {
    props.s = currentSession;
  } else {
    props.s = '00000000000' + 'x' + randomFactor;
  }

  // Config values, countries and regions
  props.cC = hydraConfig.params.cC;
  props.bR = hydraConfig.params.bR;

  // Matrix Version
  if (undefined !== regionConfig.version) {
    props.mv = regionConfig.version;
  }

  // Current page
  props.cP = getCurrentPath();

  // Environment
  if (ninjaConfig.environment !== 'production') {
    props.env = 'dev';
  }

  // Timestamp
  props.t = new Date().getTime();

  props = applyPlatformFn(props);

  // hydra POST requires specific body structure
  var hydraPostBody = window.JSON.stringify({
    tracks: [objectToQueryString(props)],
  });
  // console.log(props, hydraPostBody, url);
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, hydraPostBody);
  } else {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: hydraPostBody,
      keepalive: true,
    });
  }
}

export function cleanupCookies() {}

// export functions
export var trackEvent = ninja.trackEvent;
export var trackPage = ninja.trackPage;
export var checkParam = ninja.checkParam;
