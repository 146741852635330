export var NINJA_CDN = 'https://ninja.data.olxcdn.com';

export var SPLITTER_CDN = 'https://laquesis.data.olxcdn.com';

export var JSQR_URL = 'https://unpkg.com/jsqr@1.4.0/dist/jsQR.js';

export var WEB_VITALS_URL = 'https://unpkg.com/web-vitals@3.3.1/dist/web-vitals.iife.js';

// Load `/next/` suffix until all regions are migrated
// TODO: Update deploy scripts for qa and survey when done
export var LAQUESIS_QA_URL = NINJA_CDN + '/next/laquesisqa.js';

export var LAQUESIS_SURVEY_URL = NINJA_CDN + '/next/laquesisSurvey.js';

export var trackingMap = {
  H: 'hydra',
  hydra: 'hydra',
  GA: 'ga',
  ga: 'ga',
  WA: 'wa',
  wa: 'wa',
  M: 'mixpanel',
  mixpanel: 'mixpanel',
  CT: 'clevertap',
  clevertap: 'clevertap',
  G: 'gtm',
  gtm: 'gtm',
  LQ: 'laquesis',
  laquesis: 'laquesis',
  GTAG: 'gtag',
  F: 'facebook',
  facebook: 'facebook',
  BRAZE: 'braze',
};

export var Trackers = {
  H: 'H', // Hydra
  LQ: 'LQ', // Laquesis
  M: 'M', // Mixpanel
  GTM: 'G', // GTM
  GA: 'GA', // GA
  GTAG: 'GTAG', // GA4 a.k.a. GTAG
  CT: 'CT', // Clevertap,
  BRAZE: 'BRAZE', // Braze

  /* Apps only */
  F: 'F', // Facebook
  AF: 'A', // Appsflyer
  AD: 'AD', // Adjust
  NR: 'NR', // NewRelic
};

export var EVENTS = {
  LAQUESIS_READY: 'laquesis/ready',
  LAQUESIS_FLAG_ASSIGNMENT: 'laquesis/flagAssignment',
  LAQUESIS_EXP_ASSIGNMENT: 'laquesis/experimentAssignment',
  LAQUESIS_EXP_IMPRESSION: 'laquesis/experimentImpression',
};

export var ChannelName = {
  Mobile: 'mobile-html5',
  Desktop: 'desktop',
};
