export function jsonEncode(mixed_val) {
  //       discuss at: http://phpjs.org/functions/json_encode/
  //      original by: Public Domain (http://www.json.org/json2.js)
  // reimplemented by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  //      improved by: Michael White
  //         input by: felix
  //      bugfixed by: Brett Zamir (http://brett-zamir.me)
  //        example 1: json_encode('Kevin');
  //        returns 1: '"Kevin"'
  /*
           http://www.JSON.org/json2.js
           2008-11-19
           Public Domain.
           NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
           See http://www.JSON.org/js.html
           */
  try {
    var retVal;
    var json = window.JSON;
    var quote = function (str) {
      var string = str;
      try {
        string = encodeURIComponent(decodeURIComponent(string));
      } catch (ignore) {
        //
      }
      /* eslint-disable */
      var escapable =
          /[\\\"\u0000-\u001f\u007f-\u009f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        meta = {
          // table of character substitutions
          '\b': '\\b',
          '\t': '\\t',
          '\n': '\\n',
          '\f': '\\f',
          '\r': '\\r',
          '"': '\\"',
          '\\': '\\\\',
        };
      /* eslint-enable */
      escapable.lastIndex = 0;
      /* eslint-enable */
      return escapable.test(string)
        ? '"' +
            string.replace(escapable, function (a) {
              var c = meta[a] || null;
              return typeof c === 'string' ? c : a;
            }) +
            '"'
        : '"' + string + '"';
    };
    var str = function (key, holder) {
      // The loop counter.
      var i = 0;
      // The member key.
      var k = '';
      // The member value.
      var v = '';
      var length = 0;
      var partial = [];
      var value = holder[key];
      // If the value has a toJSON method, call it to obtain a replacement value.
      if (value && typeof value === 'object' && typeof value.toJSON === 'function') {
        value = value.toJSON(key);
      }
      // What happens next depends on the value's type.
      switch (typeof value) {
        case 'string':
          return quote(value);
        case 'number':
          // JSON numbers must be finite. Encode non-finite numbers as null.
          return isFinite(value) ? String(value) : 'null';
        case 'boolean':
          // If the value is a boolean or null, convert it to a string. Note:
          return String(value);
        case 'object':
          // If the type is 'object', we might be dealing with an object or an array or
          // null.
          // Due to a specification blunder in ECMAScript, typeof null is 'object',
          // so watch out for that case.
          if (!value) {
            return 'null';
          }
          // Make an array to hold the partial results of stringifying this object value.
          partial = [];
          // Is the value an array?
          if (Object.prototype.toString.apply(value) === '[object Array]') {
            // The value is an array. Stringify every element. Use null as a placeholder
            // for non-JSON values.
            length = value.length;
            for (i = 0; i < length; i = i + 1) {
              partial[i] = str(i, value) || 'null';
            }
            // Join all of the elements together, separated with commas, and wrap them in brackets.
            v = partial.length === 0 ? '[]' : '[' + partial.join(',') + ']';
            return v;
          }
          // Iterate through all of the keys in the object.
          for (k in value) {
            if (Object.prototype.hasOwnProperty.call(value, k)) {
              v = str(k, value);
              if (v) {
                partial.push(quote(k) + ':' + v);
              }
            }
          }
          // Join all of the member texts together, separated with commas,
          // and wrap them in braces.
          v = partial.length === 0 ? '{}' : '{' + partial.join(',') + '}';
          return v;
        default:
          // Fall-through
          throw new SyntaxError('json_encode');
      }
    };
    if (typeof json === 'object' && typeof json.stringify === 'function') {
      // Errors will not be caught here if our own equivalent to resource
      retVal = json.stringify(mixed_val);
      //  (an instance of PHPJS_Resource) is used
      if (retVal === undefined) {
        throw new SyntaxError('json_encode');
      }
    } else {
      // Make a fake root object containing our value under the key of ''.
      // Return the result of stringifying the value.
      retVal = str('', { '': mixed_val });
    }
    try {
      retVal = encodeURIComponent(decodeURIComponent(retVal));
    } catch (ignore) {
      retVal = null;
    }
    return retVal;
  } catch (error) {
    // Ensure error handling above throws a SyntaxError in all cases where it could
    // (i.e., when the JSON global is not available and there is an error)
    if (!(error instanceof SyntaxError)) {
      throw new Error('Unexpected error type in json_encode()');
    }
    return null;
  }
}
