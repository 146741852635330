import { configTracking } from './configTracking';

var cookieDomain;
var environment;

if (configTracking && configTracking.environment) {
  if (configTracking.environment.length > 0) {
    environment = configTracking.environment;
  } else {
    environment = 'production';
  }
} else {
  environment = 'production';
}

if (environment === 'production') {
  if (configTracking && configTracking.siteUrl) {
    cookieDomain = configTracking.siteUrl.match(/^[\w\d\-]+\.(.*)/);
    if (cookieDomain === null) {
      cookieDomain = configTracking.siteUrl;
    } else {
      cookieDomain = cookieDomain[1];
    }
  }
} else {
  cookieDomain = window.location.hostname || null;
}

window.dataLayer = window.dataLayer || [];
window.dataLayerDelayed = window.dataLayerDelayed || [];

function defaultValue(key, value) {
  if (configTracking && Object.prototype.hasOwnProperty.call(configTracking, key)) {
    return configTracking[key];
  }
  return value;
}

var ninjaConfig = {
  /**
   * @type {String}
   */
  environment: environment,
  /**
   * @type {String}
   */
  cookieDomain: cookieDomain,
  /**
   * @type {String[]}
   */
  specialNames: ['trackPage', 'trackEvent', 'trackLinkEvent', 'cleanup', 'event', 'processed'],
  /**
   * @type {String}
   */
  siteUrl: defaultValue('siteUrl', ''),
  /**
   * @type {function}
   */
  callBack: defaultValue('backendCallBack', null),
  /**
   * @type {String}
   */
  platform: defaultValue('platform', null),
  /**
   * @type {boolean}
   */
  debug: defaultValue('debug', null),
  /**
   * @type {boolean}
   */
  unitTest: defaultValue('unitTest', false),
  /**
   * @type {Object}
   */
  custom: defaultValue('setup', null),
  /**
   * @type {Array}
   */
  plugins: defaultValue('plugins', null),
  /**
   * @type {any[]}
   */
  dataLayer: window[defaultValue('dataLayer', 'dataLayer')],
  /**
   * @type {any[]}
   */
  dataLayerDelayed: window[defaultValue('dataLayerDelayed', 'dataLayerDelayed')],
  laquesisQa: defaultValue('laquesisQa', null),
  /**
   * @type {boolean}
   */
  disablePropertyPropagation: defaultValue('disablePropertyPropagation', false),
  /**
   * @type {boolean}
   */
  disableDefaultTrackPage: defaultValue('disableDefaultTrackPage', false),
  /**
   * @type {boolean}
   */
  debugEnableLogging: defaultValue('debugEnableLogging', false),
  /**
   * @type {number}
   */
  debugLogPercentage: defaultValue('debugLogPercentage', 0.01),
  /**
   * @type {Number | String}
   */
  userId: defaultValue('userId', null),

  /**
   * @type {Function | null}
   */
  getEprivacySDK: defaultValue('getEprivacySDK', null),
};

export default ninjaConfig;
