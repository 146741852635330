import { trackingMap } from '@ninja/ninja/const';
import { CategoryCodes, isConsentGiven, subscribeToConsentChange, waitForUserDecision } from '@olxeu-eprivacy-storage/js';
import { configTracking } from './config/configTracking';
import ninjaConfig from './config/ninja';
import regionConfig from './config/region';
import { cleanCookie, initCookieStorage, isEprivacyStorageRequired } from './cookies';
import { checkParam, currentSession, currentTracker, getPluginList, getTrackerList, trackError } from './core';
import { flushDelayed, trackEventDelayed, trackPageDelayed } from './core/delayedTracking';
import trackers from './core/trackers';
import { init as initLaquesis } from './trackers/laquesis';
import { eucex, extend, getCurrentPath, inArray, isArray } from './utils';

// TODO: Find a better way to get this info from the SDK
var REQUIRED_CATEGORIES = [CategoryCodes.C0002, CategoryCodes.C0003];
var originalDataLayerPush;

function initNinja() {
  try {
    var foundTrackPage;
    var tmp;

    if (regionConfig.custom[ninjaConfig.siteUrl]) {
      // Setup dataLayer
      ninjaConfig.dataLayer = ninjaConfig.dataLayer || [];
      ninjaConfig.dataLayerDelayed = ninjaConfig.dataLayerDelayed || [];

      // Overwrite function push
      if (!originalDataLayerPush) {
        originalDataLayerPush = ninjaConfig.dataLayer.push;
      }
      ninjaConfig.dataLayer.push = function (params) {
        var result = originalDataLayerPush.apply(ninjaConfig.dataLayer, [params]);

        checkParam();

        return result;
      };

      // Set internal queue
      if (!window.trackingQueue) {
        window.trackingQueue = [];
      }
      window.trackingQueue.push = function (func) {
        if (typeof func === 'function') {
          func();
          return 0;
        }
        return -1;
      };

      while (window.trackingQueue.length > 0) {
        window.trackingQueue.push(window.trackingQueue.shift());
      }

      // Call async check
      for (var j in ninjaConfig.dataLayer) {
        if (Object.prototype.hasOwnProperty.call(ninjaConfig.dataLayer, j)) {
          tmp = ninjaConfig.dataLayer[j];
          if (tmp.trackPage) {
            foundTrackPage = true;
          }
        }
      }
      if (!foundTrackPage && !ninjaConfig.disableDefaultTrackPage) {
        if (!configTracking.unitTest) {
          if (window.location.pathname) {
            // Track the current path name if there is no trackPage when the library is called
            ninjaConfig.dataLayer.push({ trackPage: eucex(getCurrentPath()) });
          } else {
            ninjaConfig.dataLayer.push({ trackPage: 'home' });
          }
        }
      } else {
        checkParam();
      }

      // Init laquesis

      if (inArray(getPluginList(), 'LQ')) {
        setTimeout(initLaquesis, 0);
      }
    }
  } catch (error) {
    if (typeof trackError === 'function') {
      trackError('JAVASCRIPT_ERROR', currentTracker, '', error);
    }
  }
}

function cleanupNinjaCookies() {
  var usedCookies = ['onap', 'lqonap', 'laquesis', 'laquesisff', 'laquesissu', 'lqstatus', 'laquesisqa', 'invite', 'ldTd'];

  for (var i = 0; i < usedCookies.length; i++) {
    cleanCookie(usedCookies[i]);
  }
}

function cleanupNinja() {
  var index;
  var length;
  var trackerList;
  var tracker;

  cleanupNinjaCookies();

  if (ninjaConfig.dataLayer && originalDataLayerPush) {
    ninjaConfig.dataLayer.push = originalDataLayerPush; //removes Ninja logic from dataLayer push method - no tracking will be sent
  }

  trackerList = getTrackerList();
  length = trackerList.length;

  // disable each tracker if cleanup logic exists
  for (index = 0; index < length; index++) {
    tracker = trackingMap[trackerList[index]];
    if (typeof trackers[tracker].cleanup === 'function') {
      trackers[tracker].cleanup();
    }
  }
}

/** Check for consent for all required categories */
function hasRequiredConsent() {
  var ret = true;

  for (var i = 0; i < REQUIRED_CATEGORIES.length; i++) {
    ret = ret && isConsentGiven(REQUIRED_CATEGORIES[i]);

    if (!ret) {
      break;
    }
  }

  return ret;
}

/**
 * Decide what to do when user has given consent and cookie storage is ready
 */
function initCallback() {
  if (hasRequiredConsent()) {
    setTimeout(function () {
      initNinja();
    }, 100);
  } else {
    cleanupNinja(); // clean all cookies if consent is not given and disable trackers
  }
}

/** Start ninja load */

// Check if current region requires the ePrivacy SDK
if (isEprivacyStorageRequired()) {
  // Wait for user to click the cookie banner
  waitForUserDecision().then(function () {
    initCookieStorage(function cb() {
      initCallback();

      // listen for consent change - if given -> init ninja, if revoked -> cleanup
      subscribeToConsentChange(function consentChanged() {
        initCallback();
      });
    });
  });
} else {
  // current behaviour - init everything and use cookieStorage with no restrictions
  initCookieStorage(function cb() {
    setTimeout(function () {
      initNinja();
    }, 100);
  });
}

// exports.getIdentifiers = function () {
//   return getIdentifiers();
// };

/**
 * Track ninja event
 * @param {String | String[]} eventName Event to track
 * @param {Record<string, any>} params
 */
function trackEvent(eventName, params) {
  var trackParams;
  if (eventName && ninjaConfig.dataLayer) {
    trackParams = extend(params || {}, {
      trackEvent: isArray(eventName) ? eventName : [eventName],
    });
    ninjaConfig.dataLayer.push(trackParams);
  }
}

/**
 * Track page
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
function trackPage(name, params) {
  var trackParams = extend(params || {}, { trackPage: name });

  ninjaConfig.dataLayer.push(trackParams);
}

export default {
  currentSession: currentSession,
  trackEvent: trackEvent,
  trackPage: trackPage,

  trackEventDelayed: trackEventDelayed,
  trackPageDelayed: trackPageDelayed,
  flushDelayed: flushDelayed,
};
