import { configTracking } from '../config/configTracking';
import hydraConfig from '../config/hydra';
import ninjaConfig from '../config/ninja';
import { ChannelName } from '../const';
import { collectCalls, currentSessionLong, getDefaultParams as ninjaGetDefaultParams, trackError, trackWebVital } from '../core';
import { getHydraHost } from '../core/utils';
import { makeMapping, makeObjectMapping } from '../mapping';
import { deucex, eucex, getCurrentPath, getProtocol, inArray, objectToQueryString } from '../utils';
import { jsonEncode } from '../utils/json/encode';
import { loadWebVitals } from '../web-vitals';

var TRACKER_KEY = 'H';

// Link event
var isLinkEvent = false;

// Internal vars
// var config = null;
// var finished = false;
// var iframeReady = true;
var initPushed = false;
var lastCurrentPage = null;
export var lastEventName = null;
export var lastTrackPage = null;

// Web vitals need the first page event
export var firstTrackPage = null;
export var currentPlatform;

// Internal function - Add Listener only one time
function init() {
  if (!initPushed) {
    // iframeReady = true;
    initPushed = true;
    lastCurrentPage = null;
    lastTrackPage = null;
    firstTrackPage = null;
    lastEventName = null;
  }
}

/**
 * Get list of Hydra-only default params
 * @param {Record<string, any>} params Tracking params
 * @returns {Record<string, any>}
 */
function getDefaultParams(params) {
  var defaultParams = {};
  var key;
  var mapped;

  // Session
  defaultParams.sl = params.sessionParams.sessionLong;
  defaultParams.s = params.sessionParams.session;
  defaultParams.cl = params.sessionParams.sessionCountLong;
  defaultParams.c = params.sessionParams.sessionCount;

  if (params.sessionParams.noCookie) {
    defaultParams.nc = 1;
  }

  // Timestamp
  defaultParams.t = new Date().getTime();

  // Discover some params from the url
  if (params.invite) {
    defaultParams.iv = eucex(params.invite);
  }

  // Host
  if (params.host) {
    defaultParams.host = eucex(params.host);
  }

  // Hash
  if (params.hash) {
    if (ninjaConfig.siteUrl !== 'www.console.data.olx.org' && ninjaConfig.siteUrl !== 'www.console-stg.data.olx.org') {
      defaultParams.hash = eucex(params.hash);
    }
  }

  // Referrer
  if (params.referrer) {
    defaultParams.referer = eucex(params.referrer);
  }

  // Default invite
  defaultParams.ivd = 'olx-' + hydraConfig.params.cC.toLowerCase() + '_organic';

  if (undefined !== hydraConfig.params.rE) {
    defaultParams.rE = hydraConfig.params.rE;
  }

  // Current page
  if (params.eventData.category) {
    // Add current pageName
    if (!params.pageName) {
      if (lastCurrentPage) {
        defaultParams.cP = lastCurrentPage;
      }
    }
  } else if (params.pageName) {
    lastCurrentPage = eucex(getPageName());
    defaultParams.cP = lastCurrentPage;
  }

  if (!configTracking.unitTest) {
    defaultParams.js = 1;
  }

  for (key in defaultParams) {
    if (Object.prototype.hasOwnProperty.call(defaultParams, key)) {
      mapped = makeMapping(TRACKER_KEY, key, params.customParams[key]);

      if (mapped.key === false) {
        continue;
      }
    }
  }

  return defaultParams;
}

/**
 * Reads config for platform property and applies it to the already mapped params
 */
export function applyPlatform(mappedParams) {
  // fallback to a default prop name if the region config lacks specific definition
  var property = hydraConfig.platform_property || 'platform';

  // first we try to take the platform defined in ninjaConfig
  if (ninjaConfig.platform === 'd') {
    mappedParams[property] = ChannelName.Desktop;
    currentPlatform = ChannelName.Desktop;
  } else if (ninjaConfig.platform === 'm') {
    mappedParams[property] = ChannelName.Mobile;
    currentPlatform = ChannelName.Mobile;
  } else {
    // Check platform only if we know the field
    if (mappedParams[property]) {
      // Store the platform value if exists and is different
      if (mappedParams[property] !== currentPlatform) {
        currentPlatform = mappedParams[property];
      }
    } else if (currentPlatform) {
      // If there is no platform, use the last value found
      mappedParams[property] = currentPlatform;
    }
  }
  return mappedParams;
}

// Internal function - Get the url to call
function getUrl(type, params) {
  var url;
  var eventData = [];
  var key;
  var newValues;
  var urlParams = {};
  var customParams = getCustomParams(params); // mapping is inside
  var ninjaDefaultParams = makeObjectMapping(TRACKER_KEY, ninjaGetDefaultParams(params));
  var moduleDefaultParams = makeObjectMapping(TRACKER_KEY, getDefaultParams(params));

  // make sure platform field is set AFTER all mappings
  customParams = applyPlatform(customParams);

  url = getProtocol() + getHydraHost() + type + '?';

  // eventName, (trackPage) and eventType
  if (params.eventData.category) {
    if (params.eventData.category) {
      eventData.push(params.eventData.category);
    }
    if (params.eventData.action) {
      eventData.push(params.eventData.action);
    }
    if (params.eventData.label) {
      eventData.push(params.eventData.label);
    }
    if (params.eventData.value) {
      eventData.push(params.eventData.value);
    }

    lastEventName = eucex(convertString(eventData.join('_')));
    newValues = makeMapping(TRACKER_KEY, 'trackEvent', lastEventName);
    if (newValues.key === false || newValues.value === false) {
      return '';
    }

    // eN and rT are critical params and we do not check them against the matrix
    lastEventName = newValues.value;
    urlParams.eN = lastEventName;

    if (inArray(hydraConfig.triggeredEvents, lastEventName)) {
      urlParams.rT = 1;
    }
  }

  if (params.pageName) {
    // Add original trackPage
    newValues = makeMapping(TRACKER_KEY, 'trackPage', params.pageName);
    if (newValues.key === false || newValues.value === false) {
      return '';
    }

    // eN and rT are critical params and we do not check them against the matrix
    lastTrackPage = eucex(newValues.value);

    if (!firstTrackPage) {
      firstTrackPage = lastTrackPage;
    }

    urlParams.eN = lastTrackPage;

    if (inArray(hydraConfig.triggeredEvents, lastTrackPage)) {
      urlParams.rT = 1;
    }
  }

  // Order matters! - GET requests can get truncated for being too long.
  // Make sure the default params are always upfront
  // Encode shared default params first
  for (key in ninjaDefaultParams) {
    if (Object.prototype.hasOwnProperty.call(ninjaDefaultParams, key)) {
      urlParams[eucex(key)] = eucex(ninjaDefaultParams[key]);
    }
  }

  // Encode tracker default params second
  for (key in moduleDefaultParams) {
    if (Object.prototype.hasOwnProperty.call(moduleDefaultParams, key)) {
      urlParams[eucex(key)] = eucex(moduleDefaultParams[key]);
    }
  }

  // Encode custom params. Check first for name clash with default params
  for (key in customParams) {
    if (Object.prototype.hasOwnProperty.call(customParams, key)) {
      if (!moduleDefaultParams[key] && !ninjaDefaultParams[key]) {
        if (key !== 'extra') {
          urlParams[eucex(key)] = eucex(customParams[key]);
        } else {
          urlParams[eucex(key)] = jsonEncode(customParams[key]);
        }
      }
    }
  }

  // reverse order so default properties are first in the list

  // Convert object to query string
  url += objectToQueryString(urlParams);

  if (typeof configTracking.debug === 'function') {
    if (lastTrackPage) {
      configTracking.debug('hydra', [{ customParams: customParams }, { pageName: lastTrackPage }]);
    } else if (params.eventData.category) {
      configTracking.debug('hydra', [{ eventName: lastEventName }]);
    }
  }

  return url;
}

// Internal function - Translate pageName
function getPageName() {
  return getCurrentPath();
}

function getCustomParams(params) {
  var key;
  var customParams = {};
  var newValues;

  // Set custom values with unknown name
  for (key in params.customParams) {
    if (Object.prototype.hasOwnProperty.call(params.customParams, key) && typeof params.customParams[key] !== 'function') {
      // Transform all
      newValues = makeMapping(TRACKER_KEY, key, params.customParams[key]);
      // console.debug(newValues);
      if (newValues.key === false) {
        continue;
      }

      if (newValues.key === 'trackEvent') {
        customParams[newValues.key] = newValues.value;
      } else if (inArray(['impressions', 'adImpressions', 'ad_impressions'], newValues.key)) {
        // TODO: Remove this with the bad ninja
        customParams[newValues.key] = jsonEncode(newValues.value);
      } else if (inArray(hydraConfig.knownParameters, newValues.key)) {
        if (typeof newValues.value !== 'object') {
          customParams[newValues.key] = deucex(newValues.value);
          if (customParams[newValues.key] === '' && typeof newValues.value === 'string') {
            customParams[newValues.key] = deucex(newValues.value.replace(/%/g, ''));
          }
        } else {
          customParams[newValues.key] = jsonEncode(newValues.value);
        }
      } else {
        if (!inArray(hydraConfig.ignoreParams, newValues.key)) {
          // Add to extra all the unknown variables
          if (!customParams.extra) {
            customParams.extra = {};
          }
          if (typeof newValues.value !== 'object') {
            if (
              typeof newValues.value === 'string' && // Is an string
              newValues.value.length > 6 && // With more than 6 chars
              newValues.value.slice(0, 3) === '%5B' && // The first 3 are a '[' encoded
              newValues.value.slice(-3) === '%5D' // The last 3 are a ']' encoded
            ) {
              // Remove the encoded '[' and ']'
              // Remove the encoded '"' and "'"
              // Split by the encoded ',' to generate an array
              // Send it as array
              customParams.extra[newValues.key] = newValues.value
                .replace(/%5B/g, '')
                .replace(/%5D/g, '')
                .replace(/%22/g, '')
                .replace(/%27/g, '')
                .split('%2C');
            } else {
              customParams.extra[newValues.key] = eucex(newValues.value);
            }
          } else {
            customParams.extra[newValues.key] = newValues.value;
          }
        }
      }
    }
  }

  return customParams;
}

// Internal function - Make the call using img
function trackWithImage(src) {
  var hydraImage;

  if (!configTracking.unitTest) {
    hydraImage = new Image();
    hydraImage.onload = function () {
      onHitSentOk();
      hydraImage.onload = null;
      hydraImage.onerror = null;
    };
    hydraImage.onerror = function () {
      trackError('HIT_FAIL', 'Hydra', 'trackWithImage', src);
      hydraImage.onload = null;
      hydraImage.onerror = null;
    };
    hydraImage.src = src;
  }
}

// Internal function - Callback after finish one hit
function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}

export function convertString(text) {
  if (typeof text === 'string') {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9\/_]/g, '-')
      .replace(/\s{2}/g, ' ')
      .replace(/\s/g, '-')
      .replace(/---/g, '-')
      .replace(/---/g, '-')
      .replace(/--/g, '-');
  }
  return text;
}

// Entry function - Track a page
export function trackPage(params) {
  var url;

  // finished = false;
  init();
  url = getUrl(hydraConfig.path, params);

  if (url.length === 0) {
    onHitSentOk();
  } else {
    trackWithImage(url);
  }

  /**
   * load vitals after first page event. It doesn't matter when the library is loaded as
   * the metrics are stored by the browser and are available at any time.
   * There's a check inside for browser compatibility and load the library only once
   */
  if (hydraConfig.vitals && hydraConfig.vitals_path) {
    loadWebVitals(
      /**
       * @param {PerformanceMetric} metric
       */
      function (metric) {
        // console.log(metric.name, firstTrackPage, metric);
        trackWebVital(metric, firstTrackPage, applyPlatform);
      }
    );
  }
  // finished = true;
}

// Entry function - Track an event
export function trackEvent(params) {
  // finished = false;
  trackPage(params);
  // finished = true;
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

export function getIdentifier() {
  return currentSessionLong;
}
