import ninjaConfig from '@ninja/ninja/config/ninja';

export var SEED_INT = 2147483647;
export var randomFactor = generateRandomFactor();

export function generateRandomFactor() {
  return Number(Math.floor(Math.random() * SEED_INT + 1));
}

export function generateSession() {
  var session = Number(new Date().getTime()).toString(16) + 'x' + randomFactor.toString(16);

  // Resetting randomFactor for the next use
  randomFactor = generateRandomFactor();
  return session;
}

/**
 * Internal function - Extract the part of the session string responsible for the randomness and parse it into number
 * @param {string} session
 * @returns number
 */
export function extractSessionRandomFactor(session) {
  try {
    return parseInt(session.split('x')[1], 16);
  } catch (ignore) {
    return 0;
  }
}

/**
 * Internal function - Check if debug mode is on. If yes, based on debugLogPercentage,
 * determine if logs from a given session should be collected. If the session is not available yet,
 * the randomFactor used in the creation of the next session is taken.
 * @param {string|null} session
 * @returns boolean
 */
export function checkIfDebugEligible(session) {
  try {
    // Enable debugging for custom brand/country with 100% of users - we log only 1 message conditionally
    // var percentage = 1;

    // if (ninjaConfig.siteUrl === 'www.olx.pl' && ninjaConfig.environment === 'production') {
    //   if (session) {
    //     return extractSessionRandomFactor(session) / SEED_INT <= percentage;
    //   }

    //   return randomFactor / SEED_INT <= percentage;
    // }
    // end

    if (!ninjaConfig.debugEnableLogging || !(ninjaConfig.debugLogPercentage > 0)) {
      return false;
    }

    if (session) {
      return extractSessionRandomFactor(session) / SEED_INT <= ninjaConfig.debugLogPercentage;
    }

    return randomFactor / SEED_INT <= ninjaConfig.debugLogPercentage;
  } catch (ignore) {
    return false;
  }
}
